import React from 'react';
import UserModal from '../modals/user-modal';
import CommonManagement from '../../components/common-management';
import Header from '../../components/header';

function UnapprovedUsers() {
    return (
        <>
            <Header current="賃借人管理" />
            <CommonManagement
                endpoint={["/users"]}
                dropdownOptions={[
                    { value: 'userName', label: '氏名' },
                    { value: 'userKana', label: '氏名カナ' },
                ]}
                tableHeaders={['氏名', '氏名カナ', '電話番号', 'メールアドレス', '郵便番号', '住所']}
                tableRowData={(user) => [
                    user.user_name,
                    user.user_kana,
                    user.phone_number,
                    user.mail_address,
                    user.zip_code,
                    user.address + (user.address2 ? user.address2 : ''),
                ]}
                DetailModal={UserModal}
                approvalFlg="1"
                buttonType="management"
            />
        </>
    );
}

export default UnapprovedUsers;
