import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { Box, Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';
import RenderTextField from '../../components/forms/render-text-field';

const TcuSettingModal = ({ item, onClose }) => {
    const settings = item;
    const [niMH, setNiMH] = useState(settings.ni_mh_req);
    const { handleSubmit, control, formState: { errors } } = useForm({
        defaultValues: {
            igOffInterval: settings.ig_off_interval,
            igOnInterval: settings.ig_on_interval,
            lowBatteryVoltageThreshold: settings.low_battery_voltage_threshold,
            suddenBatteryDropThreshold: settings.sudden_battery_drop_threshold,
            suddenBatteryDropTime: settings.sudden_battery_drop_time,
            lowExternalPowerThreshold: settings.low_external_power_threshold,
            shockDetectedThreshold: settings.shock_detected_threshold,
        }
    });
    const apiUrl = process.env.REACT_APP_API_URL;

    const onSubmit = async (data) => {
        try {
            const entryData = { ...data, niMHReq: niMH };
            await axios.put(`${apiUrl}/tcu-settings/${settings.frame_number}`, entryData);
            onClose();
        } catch (error) {
            console.error('Error during TCU update:', error);
        }
    };

    const renderTextField = (name, label) => (
        <RenderTextField
            name={name}
            label={label}
            control={control}
            rules={{
                required: '入力してください',
                maxLength: { value: 15, message: '15文字以内で入力してください' },
                pattern: { value: /^[0-9]*$/, message: '半角数字のみ有効です' }
            }}
            errors={errors}
            required
        />
    );

    return (
        <Dialog open onClose={() => onClose(false)} fullWidth>
            <DialogTitle sx={{ mx: 2 }}>閾値設定</DialogTitle>
            <DialogContent sx={{ mx: 2 }}>
                <Box component="form" onSubmit={handleSubmit(onSubmit)}>
                    {renderTextField("igOnInterval", "位置情報送信間隔(エンジンON)")}
                    {renderTextField("igOffInterval", "位置情報送信間隔(エンジンOFF)")}
                    {renderTextField("lowBatteryVoltageThreshold", "バッテリー電圧低下異常閾値")}
                    {renderTextField("suddenBatteryDropThreshold", "バッテリー急低下異常の指定閾値")}
                    {renderTextField("suddenBatteryDropTime", "バッテリー急低下異常の指定時間")}
                    {renderTextField("lowExternalPowerThreshold", "外部電源電圧低下異常閾値")}
                    {renderTextField("shockDetectedThreshold", "衝撃検知閾値")}

                    <Box m={1} display="flex" alignItems="center" gap={2}>
                        <Typography variant="body1"> Ni-MH使用:</Typography>
                        <Button
                            onClick={() => setNiMH(1)}
                            variant="outlined"
                            color={niMH ? "primary" : "normal"}
                        >
                            有効
                        </Button>
                        <Button
                            onClick={() => setNiMH(0)}
                            variant="outlined"
                            color={!niMH ? "primary" : "normal"}
                        >
                            無効
                        </Button>
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions sx={{ m: 1 }}>
                <Button onClick={handleSubmit(onSubmit)} variant="contained" color="primary">確定</Button>
                <Button onClick={() => onClose(false)}>キャンセル</Button>
            </DialogActions>
        </Dialog>
    );
};

export default TcuSettingModal;
