import React from 'react';
import { Box, Button } from '@mui/material';

const SettingButtons = ({ handleDetailClick }) => {
    return (
        <Box mt={2} textAlign="center">
            <Button
                variant="contained"
                color="primary"
                onClick={() => handleDetailClick('setting')}
                sx={{ margin: 1 }}
            >
                設定
            </Button>
        </Box>
    );
};

export default SettingButtons;