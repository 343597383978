import React, { useState } from 'react';
import axios from 'axios';
import { GoogleMap, MarkerF, InfoWindowF } from '@react-google-maps/api';
import { Box, Typography, Dialog, DialogTitle, DialogContent, Button, TextField, DialogActions, Alert } from '@mui/material';

const TrackComponent = ({ tcuCode }) => {
    const [locations, setLocations] = useState([]);
    const [showMap, setShowMap] = useState(false);
    const [selectedMarker, setSelectedMarker] = useState(null);
    const [center, setCenter] = useState({ lat: 35.6809591, lng: 139.7673068 }); // 東京
    const [alert, setAlert] = useState({ show: false, message: '' });
    const now = new Date();
    const today = now.toISOString().split('T')[0];  // yyyy-MM-dd
    const currentTime = now.toTimeString().slice(0, 5); // HH:mm
    const [timeRange, setTimeRange] = useState({
        startDate: today,  
        startTime: '00:00',
        endDate: today, 
        endTime: currentTime
    });

    const apiUrl = process.env.REACT_APP_API_URL;

    const handleTimeChange = (field) => (e) => {
        setTimeRange({ ...timeRange, [field]: e.target.value });
    };

    const handlePresetClick = (preset) => {
        let startDate = '';
        let startTime = '00:00';  // デフォルトで0時
        let endDate = today;
        let endTime = currentTime;
    
        switch (preset) {
            case 'today':
                startDate = today;
                break;
            case 'week':
                now.setDate(now.getDate() - 7);
                startDate = now.toISOString().split('T')[0];
                startTime = currentTime;
                break;
            case 'month':
                now.setDate(now.getDate() - 31);
                startDate = now.toISOString().split('T')[0];
                startTime = currentTime;
                break;
            case 'threeMonths':
                now.setDate(now.getDate() - 93);
                startDate = now.toISOString().split('T')[0];
                startTime = currentTime;
                break;
            default:
                break;
        }
    
        setTimeRange({
            startDate,
            startTime,
            endDate,
            endTime
        });
    };

    const fetchLocations = async () => {
        const { startDate, startTime, endDate, endTime } = timeRange;
        if (!startDate && !endDate) {
            setAlert({ show: true, message: '期間を指定してください' });
            return;
        }

        try {
            const startTimestamp = startDate ? `${startDate} ${startTime}` : null;
            const endTimestamp = endDate ? `${endDate} ${endTime}` : null;
            const response = await axios.get(`${apiUrl}/cars/location/${tcuCode}`, {
                params: { startTimestamp, endTimestamp }
            });

            const data = response.data;
            setLocations(data);

            if (data.length > 0) {
                setCenter({ lat: data[0].lat, lng: data[0].lng });
                setShowMap(true);
            } else {
                setAlert({ show: true, message: '過去の位置情報がありません' });
            }
        } catch (error) {
            console.error('Error fetching locations:', error);
            setAlert({ show: true, message: '位置情報の取得に失敗しました。' });
        }
    };

    // マップを閉じる
    const handleCloseMaps = () => {
        setSelectedMarker(null);
        setShowMap(false);
        setLocations([]);
    };

    // マーカーの色を取得
    const getMarkerColor = (acc) => (
        `http://maps.google.com/mapfiles/ms/icons/${acc === 1 ? 'red' : 'blue'}-dot.png`
    );

    // マーカーをクリックしたときの処理
    const handleMarkerClick = (location) => {
        setSelectedMarker(location);
        setCenter({ lat: location.lat, lng: location.lng });
    };

    // テキストフィールドへの入力後にアイコンを非表示にする
    const handleBlur = (e) => {
        const inputField = e.target;
        inputField.style.setProperty('appearance', 'none'); // 入力後にアイコンを非表示にする
    };

    return (
        <>
            <Box display="flex" flexDirection="row">
                    <Button variant="outlined" sx={{ margin:1 }} onClick={() => handlePresetClick('today')}>
                        今日
                    </Button>
                    <Button variant="outlined" sx={{ margin:1 }} onClick={() => handlePresetClick('week')}>
                        過去一週間
                    </Button>
                    <Button variant="outlined" sx={{ margin:1 }} onClick={() => handlePresetClick('month')}>
                        過去一か月
                    </Button>
                    <Button variant="outlined" sx={{ margin:1 }} onClick={() => handlePresetClick('threeMonths')}>
                        過去三か月
                    </Button>
                </Box>
            <Box display="flex" flexDirection="row" sx={{ marginTop: 1 }}>
                {['startDate', 'startTime', 'endDate', 'endTime'].map((field) => (
                    <TextField
                        key={field}
                        label={field.includes('Date') 
                            ? (field.includes('start') ? '開始日' : '終了日') 
                            : (field.includes('start') ? '開始時間' : '終了時間')}
                        type={field.includes('Date') ? 'date' : 'time'}
                        value={timeRange[field] || timeRange}
                        size="small"
                        onChange={handleTimeChange(field)}
                        onBlur={handleBlur}
                        InputLabelProps={{ shrink: true }}
                        sx={{ marginRight: 1, width: field.includes('Time') ? '110px' : '140px' }}
                    />
                ))}
            </Box>
            <Button 
                variant="text" 
                color="primary" 
                style={{ textDecoration: 'underline', minWidth: 'unset', textTransform: 'none' }} 
                onClick={() => fetchLocations()}
            >
                Googleマップで表示
            </Button>

            {alert.show && (
                <Alert severity="error" onClose={() => setAlert({ show: false })}>
                    {alert.message}
                </Alert>
            )}

            <Dialog open={showMap} onClose={() => handleCloseMaps()} fullWidth maxWidth="lg">
                <DialogTitle>位置情報の軌跡</DialogTitle>
                <DialogContent>
                    <GoogleMap
                        mapContainerStyle={{ height: '500px', width: '100%' }}
                        zoom={12}
                        center={center}
                    >
                        {locations.map((location, index) => (
                            <MarkerF
                                key={index}
                                position={{ lat: location.lat, lng: location.lng }}
                                icon={getMarkerColor(location.acc)}
                                onClick={() => handleMarkerClick(location)}
                            />
                        ))}

                        {selectedMarker && (
                            <InfoWindowF
                                position={{ lat: selectedMarker.lat, lng: selectedMarker.lng }}
                                onCloseClick={() => setSelectedMarker(null)}
                            >
                                <Box>
                                    <Typography variant="body1">緯度: {selectedMarker.lat}</Typography>
                                    <Typography variant="body1">経度: {selectedMarker.lng}</Typography>
                                    <Typography variant="body1">エンジン: {selectedMarker.acc ? 'ON' : 'OFF'}</Typography>
                                    <Typography variant="body1">日時: {selectedMarker.dateTime}</Typography>
                                </Box>
                            </InfoWindowF>
                        )}
                    </GoogleMap>
                    <Box sx={{ marginTop: 2, display: 'flex', justifyContent: 'space-around' }}>
                        <Box display="flex" alignItems="center">
                            <img
                                src="http://maps.google.com/mapfiles/ms/icons/red-dot.png"
                                alt="Red Marker"
                                style={{ width: 24, height: 24, marginRight: 8 }}
                            />
                            <Typography variant="body2">エンジンON</Typography>
                        </Box>
                        <Box display="flex" alignItems="center">
                            <img
                                src="http://maps.google.com/mapfiles/ms/icons/blue-dot.png"
                                alt="Blue Marker"
                                style={{ width: 24, height: 24, marginRight: 8 }}
                            />
                            <Typography variant="body2">エンジンOFF</Typography>
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleCloseMaps()}>閉じる</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default TrackComponent;
