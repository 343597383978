import React from 'react';
import { Controller } from 'react-hook-form';
import { TextField } from '@mui/material';

const RenderTextField = ({ name, label, control, rules, errors, ...props }) => (
    <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field }) => (
            <TextField
                {...field}
                label={label}
                fullWidth
                size="small"
                margin="normal"
                error={!!errors[name]}
                helperText={errors[name]?.message || ''}
                {...props}
            />
        )}
    />
);

export default RenderTextField;
