import React, { useState, useEffect, useContext } from 'react';
import { useForm, Controller } from 'react-hook-form';
import axios from 'axios';
import { Box, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, MenuItem, Select, FormControl, InputLabel, Snackbar, Alert } from '@mui/material';
import { AuthContext } from '../../auth/AuthProvider';
import RenterSelect from '../../components/forms/renter-select';
import LoadingSpinner from '../../components/loading-spinner';
import RenderTextField from '../../components/forms/render-text-field';

const AdminModal = ({ item, onClose }) => {
    const admin = item;
    const { handleSubmit, control, setValue, watch, formState: { errors } } = useForm({
        defaultValues: {
            adminName: admin ? admin.admin_name : '',
            mailAddress: admin ? admin.mail_address : '',
            phoneNumber: admin ? admin.phone_number : '',
            renterCode: '',
            authorityLevel: admin ? admin.authority_level : '',
        }
    });
    const [renters, setRenters] = useState([]);
    const [renter, setRenter] = useState(admin ? admin.renter_code : '');
    const { auth } = useContext(AuthContext);
    const loginUser = auth.user;
    const apiUrl = process.env.REACT_APP_API_URL;
    const selectedRenter = watch('renterCode');
    const [alert, setAlert] = useState({ show: false, message: '' });
    const [openSnackbar, setOpenSnackbar] = useState(false);

    // 会社名取得
    useEffect(() => {
        const fetchRenters = async () => {
            if (!loginUser) return;
            try {
                const response = await axios.get(`${apiUrl}/renters`, { params: { loginUser } });
                const fetchedRenters = response.data;
    
                if (loginUser.authorityLevel === 2) {
                    setRenter(fetchedRenters);
                    setValue('renterCode', fetchedRenters.renter_code);
                } else {
                    const additionalRenters = [
                        { renter_code: 1, renter_name: 'サージュ' },
                        { renter_code: 2, renter_name: 'ネクスト' }
                    ];
                    setRenters(fetchedRenters.concat(additionalRenters));
    
                    if (admin && (admin.authority_level === 1 || admin.authority_level === 2)) {
                        setValue('renterCode', fetchedRenters.find(renter => renter.renter_code === admin.renter_code));
                    }
                }
            } catch (error) {
                console.error('Error during renter fetch:', error);
            }
        };

        fetchRenters();
    }, [admin, apiUrl, loginUser, setValue]);

    // 編集の場合、もともとの権限によって会社名を設定
    useEffect(() => {
        if (!admin) return;

        const defaultRenter = admin.authority_level === 4 ? { renter_code: 1, renter_name: 'サージュ' }
            : admin.authority_level === 3 ? { renter_code: 2, renter_name: 'ネクスト' } : null;

        if (defaultRenter) {
            setRenter(defaultRenter);
            setValue('renterCode', defaultRenter);
        }
    }, [admin, setValue]);

    // サージュ、ネクストを会社で選択した場合、権限を設定
    useEffect(() => {
        if (admin) return;
        const authorityLevelMap = {
            1: 4,
            2: 3
        };
        setValue('authorityLevel', authorityLevelMap[selectedRenter.renter_code] || '');
    }, [selectedRenter, setValue, admin]);


    const onSubmit = async (data) => {
        const entryData = {
            adminName: data.adminName,
            mailAddress: data.mailAddress,
            phoneNumber: data.phoneNumber,
            renterCode: data.renterCode.renter_code,
            authorityLevel: data.authorityLevel,
        };
        
        try { 
            const response = admin 
            ? await axios.put(`${apiUrl}/admins/${admin.admin_code}`, entryData) // 編集
            : await axios.post(`${apiUrl}/admins`, entryData); // 登録
            if (response.data.message === 'mail address already exists') {
                setAlert({ show: true, message: 'このメールアドレスは既に登録されています' });
            } else {
                if (admin) {
                    onClose(true);
                } else {
                    setOpenSnackbar(true);
                    setTimeout(() => onClose(true), 1000);
                }
            }
        } catch (error) {
            console.error('Error during admin entry:', error);
            setAlert({ show: true, message: 'エラーが発生しました' });
        }
    };

    const renderTextField = (name, label, rules, required=false) => (
        <RenderTextField
            name={name}
            label={label}
            control={control}
            rules={rules}
            errors={errors}
            required={required}
        />
    );

    if (auth.isLoading) {
        return <LoadingSpinner />;
    }

    return (
        <>
            <Dialog open onClose={() => onClose(false)} fullWidth>
                <DialogTitle sx={{ mx: 2 }}>管理者{admin ? '編集' : '登録'}</DialogTitle>
                <DialogContent sx={{ mx: 2 }}>
                    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
                        {renderTextField("adminName", "氏名", {
                                required: '入力してください', 
                                maxLength: { value: 60, message: '60文字以内で入力してください' }
                            }, true)}
                        {renderTextField("mailAddress",  "メールアドレス", {
                                required: '入力してください', 
                                maxLength: { value: 254, message: 'メールアドレスは254文字以内で入力してください' },
                                pattern: { value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, message: 'メールアドレスの形式が正しくありません' }
                            },  true)}
                        {renderTextField("phoneNumber", "電話番号", {
                                minLength: { value: 10, message: '10桁または11桁の数字を入力してください' },
                                maxLength: { value: 15, message: '15文字以内で入力してください' },
                                pattern: { value: /^[0-9]*$/, message: '半角数字のみ有効です' }
                            })}
                        <RenterSelect
                            control={control}
                            renters={renters}
                            loginUsersRenter={renter}
                            loginUser={loginUser}
                        />
                        <FormControl fullWidth size="small" margin="normal" error={!!errors.authorityLevel}>
                            <InputLabel shrink>権限</InputLabel>
                            <Controller
                                name="authorityLevel"
                                control={control}
                                rules={{
                                    validate: value => {
                                        if ((selectedRenter !== 1 && selectedRenter !== 2) && loginUser.authorityLevel === 2) {
                                            return value !== '' || '権限を選択してください';
                                        }
                                        return true;
                                    }
                                }}
                                render={({ field }) => (
                                    (selectedRenter.renter_code === 1 || selectedRenter.renter_code === 2) ? (
                                        <TextField
                                            {...field}
                                            value={selectedRenter.renter_code === 1 ? 'サージュ' : 'ネクスト'}
                                            fullWidth
                                            size="small"
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                        />
                                    ) : (
                                        <Select
                                            {...field}
                                            label="権限"
                                            fullWidth 
                                            size="small" 
                                            displayEmpty
                                        >
                                            <MenuItem value="">
                                                <em>選択してください</em>
                                            </MenuItem>
                                            <MenuItem value={1}>一般ユーザー</MenuItem>
                                            <MenuItem value={2}>管理者</MenuItem>                                        
                                        </Select>
                                    )
                                )}
                            />
                        </FormControl>
                        {alert.show && (
                        <Alert severity="error" onClose={() => setAlert({ show: false })}>
                            {alert.message}
                        </Alert>
                        )}
                    </Box>
                </DialogContent>
                <DialogActions sx={{ m: 1 }}>
                    <Button onClick={handleSubmit(onSubmit)} variant="contained" color="primary">
                        {admin ? '確定' : '登録'}
                    </Button>
                    <Button onClick={() => onClose(false)}>キャンセル</Button>
                </DialogActions>
            </Dialog>

            <Snackbar
                open={openSnackbar}
                autoHideDuration={3000}
                onClose={() => setOpenSnackbar(false)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={() => setOpenSnackbar(false)} severity="success" sx={{ width: '100%' }}>
                    メールアドレスに仮パスワードを送信しました
                </Alert>
            </Snackbar>
        </>
    );
};

export default AdminModal;
