import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { Box, Dialog, DialogTitle, DialogContent, DialogActions, Button, Alert } from '@mui/material';
import RenderTextField from '../../components/forms/render-text-field';

const TcuModal = ({ item, onClose }) => {
    const tcu = item;
    const { handleSubmit, control, formState: { errors } } = useForm({
        defaultValues: {
            imei: tcu ? tcu.imei : '',
            ipAddress: tcu ? tcu.ip_address : '',
            phoneNumber: tcu ? tcu.phone_number : '',
        }
    });
    const apiUrl = process.env.REACT_APP_API_URL;
    const [alert, setAlert] = useState({ show: false, message: '' });

    const onSubmit = async (data) => {
        const entryData = {
            imei: data.imei,
            ipAddress: data.ipAddress,
            phoneNumber: data.phoneNumber,
        };

        try {
            const response = tcu 
                ? await axios.put(`${apiUrl}/tcu/${tcu.tcu_code}`, entryData) // 編集
                : await axios.post(`${apiUrl}/tcu`, entryData); // 登録

            if (response.data.message === 'IMEI number already exists') {
                setAlert({ show: true, message: 'このIMEI番号は既に登録されています' });
            } else if (response.data.message === 'car not found') {
                setAlert({ show: true, message: '車両が見つかりませんでした' });
            } else {
                onClose();
                }
        } catch (error) {
            if (error.response) {
                console.error('Error response:', error.response.data);
            } else {
                console.error('Error tcu modal:', error);
            }
        }
    };

    const renderTextField = (name, label, rules) => (
        <RenderTextField
            name={name}
            label={label}
            control={control}
            rules={rules}
            errors={errors}
            required
        />
    );

    return (
        <Dialog open onClose={() => onClose(false)} fullWidth>
            <DialogTitle sx={{ mx: 2 }}>車載器{tcu ? '編集' : '登録'}</DialogTitle>
            <DialogContent sx={{ mx: 2 }}>
                <Box component="form" onSubmit={handleSubmit(onSubmit)}>
                    {renderTextField('imei', 'IMEI番号', {
                        required: '入力してください',
                        maxLength: { value: 15, message: '15文字以内で入力してください' },
                        pattern: { value: /^[0-9]*$/, message: '半角数字のみ有効です' }
                    })}
                    {renderTextField('ipAddress', 'IPアドレス', {
                        required: '入力してください',
                        maxLength: { value: 40, message: '40文字以内で入力してください' },
                        pattern: { value: /^[0-9.]*$/, message: '半角数字または"."のみ有効です' }
                    })}
                    {renderTextField('phoneNumber', '電話番号(ハイフンなし)', {
                        required: '入力してください',
                        minLength: { value: 10, message: '10文字以上で入力してください' },
                        maxLength: { value: 18, message: '18文字以内で入力してください' },
                        pattern: { value: /^[0-9]*$/, message: '半角数字のみ有効です' }
                    })}
                    {alert.show && (
                        <Alert severity="error" onClose={() => setAlert({ show: false })}>
                            {alert.message}
                        </Alert>
                    )}
                </Box>
            </DialogContent>
            <DialogActions sx={{ m: 1 }}>
                <Button onClick={handleSubmit(onSubmit)} variant="contained" color="primary">
                    {tcu ? '確定' : '登録'}
                </Button>
                <Button onClick={() => onClose(false)}>キャンセル</Button>
            </DialogActions>
        </Dialog>
    );
};

export default TcuModal;
