import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import OtaToggleComponent from '../../components/ota/ota-toggle-component';

function OtaModal({ item, onClose, setAlert }) {
    return (
        <Dialog open onClose={onClose} fullWidth>
            <DialogTitle>OTA設定</DialogTitle>
            <DialogContent>
                <OtaToggleComponent items={[item]} setAlert={setAlert} onComplete={onClose} isModal={true} />
            </DialogContent>
            <DialogActions sx={{ m: 1 }}>
                <Button variant="contained" color="secondary" onClick={onClose}>
                    閉じる
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default OtaModal;
