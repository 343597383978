import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Checkbox, TableSortLabel, CircularProgress, Pagination } from '@mui/material';
import TrackComponent from './map/track-component';
import LastLocationComponent from './map/last-location-component';

function ResultsTable({ displayedList = [], selectedItem, setSelectedItem, tableHeaders, tableRowData, option, isLoading }) {
    const [sortBy, setSortBy] = useState(null);
    const [sortDirection, setSortDirection] = useState('asc');
    const [showMapItem, setShowMapItem] = useState(null);
    const [showLastLocationMap, setShowLastLocationMap] = useState(false);
    const [showTrackMap, setShowTrackMap] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const rowsPerPage = 20; // 1ページあたりの表示件数

    // displayedList が変わったときに1ページ目に戻る
    useEffect(() => {
        setCurrentPage(1);
    }, [displayedList]);


    // 管理番号を取得
    const getItemCode = (item) => {
        if (item.car_name) return item.car_code;
        if (item.user_name) return item.user_code;
        if (item.admin_code) return item.admin_code;
        if (item.renter_name) return item.renter_code;
        if (item.tcu_code) return item.tcu_code;
        return item.code;
    };

    // ソート処理
    const handleSort = (header) => {
        const isAsc = sortBy === header && sortDirection === 'asc';
        setSortBy(header);
        setSortDirection(isAsc ? 'desc' : 'asc');
    };

    // ソートされたリスト
    const sortedList = [...displayedList].sort((a, b) => {
        if (!sortBy) return 0;
        const aValue = tableRowData(a)[tableHeaders.indexOf(sortBy)];
        const bValue = tableRowData(b)[tableHeaders.indexOf(sortBy)];
        if (aValue === null || aValue === undefined) return 1;
        if (bValue === null || bValue === undefined) return -1;

        return sortDirection === 'asc' ? (aValue < bValue ? -1 : 1) : (aValue > bValue ? -1 : 1);
    });

    // 現在のページに応じて表示するデータ
    const paginatedList = sortedList.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage);

    // チェックボックスの選択処理
    const handleCheckboxChange = (item) => {
        setSelectedItem((prevSelectedItems) => {
            const isSelected = prevSelectedItems.some((selected) => getItemCode(selected) === getItemCode(item));
            return isSelected ? [] : [item];
        });
    };

    // 全選択処理
    const handleSelectAllChange = () => {
        setSelectedItem(selectedItem.length === displayedList.length ? [] : displayedList);
    };

    // 最終位置情報の表示
    const handleLastLocation = (item) => {
        setShowMapItem(item);
        setShowLastLocationMap(true);
    };

    // 位置情報の軌跡の表示
    const handleTrack = (item) => {
        setShowMapItem(item);
        setShowTrackMap(true);
    };

    // ページ変更処理
    const handleChangePage = (_, newPage) => {
        setCurrentPage(newPage);
    };

    return (
        <TableContainer component={Paper} sx={{ marginY: 1 }}>
            <Table size="small">
                <TableHead sx={{ backgroundColor: '#e8e6e6' }}>
                    <TableRow>
                        <TableCell padding="checkbox">
                            {option !== "engCut" && option !== "tcu-setting" &&
                                <Checkbox
                                    onChange={handleSelectAllChange}
                                    checked={displayedList.length !== 0 && selectedItem.length === displayedList.length}
                                />
                            }
                        </TableCell>
                        {tableHeaders.map((header, index) => (
                            <TableCell key={index}>
                                <TableSortLabel
                                    active={sortBy === header}
                                    direction={sortBy === header ? sortDirection : 'asc'}
                                    onClick={() => handleSort(header)}
                                >
                                    {header}
                                </TableSortLabel>
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {isLoading ? (
                        <TableRow>
                            <TableCell colSpan={tableHeaders.length + 1} align="center">
                                <CircularProgress />
                            </TableCell>
                        </TableRow>
                    ) : paginatedList.length === 0 ? (
                        <TableRow>
                            <TableCell colSpan={tableHeaders.length + 1} align="center">
                                該当なし
                            </TableCell>
                        </TableRow>
                    ) : (
                        paginatedList.map((item, index) => (
                            <TableRow key={index}>
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        checked={selectedItem.some((selected) => getItemCode(selected) === getItemCode(item))}
                                        onChange={() => handleCheckboxChange(item)}
                                    />
                                </TableCell>
                                {tableRowData(item).map((data, index) => (
                                    <TableCell key={index}>
                                        {data === 'lastLocation' ? (
                                            item.status?.latitude && item.status?.longitude ? (
                                                <Button
                                                    variant="text"
                                                    color="primary"
                                                    style={{ marginLeft: 5, textDecoration: 'underline', padding: 0, minWidth: 'unset', textTransform: 'none' }}
                                                    onClick={() => handleLastLocation(item)}
                                                >
                                                    Googleマップで表示
                                                </Button>
                                            ) : '未登録'
                                        ) : data === 'track' ? (
                                            item.status ? (
                                                <Button
                                                    variant="text"
                                                    color="primary"
                                                    style={{ marginLeft: 5, textDecoration: 'underline', padding: 0, minWidth: 'unset', textTransform: 'none' }}
                                                    onClick={() => handleTrack(item)}
                                                >
                                                    Googleマップで表示
                                                </Button>
                                            ) : '未登録'
                                        ) : (
                                            data || '未登録'
                                        )}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))
                    )}
                </TableBody>
            </Table>

            <Pagination
                count={Math.ceil(sortedList.length / rowsPerPage)}
                page={currentPage}
                onChange={handleChangePage}
                sx={{ display: 'flex', justifyContent: 'center', marginY: 1 }}
            />

            {showLastLocationMap && (
                <LastLocationComponent
                    car={showMapItem}
                    onClose={() => setShowLastLocationMap(false)}
                />
            )}
            {showTrackMap && (
                <Dialog open onClose={() => setShowTrackMap(false)} fullWidth>
                    <DialogTitle>位置情報の軌跡</DialogTitle>
                    <DialogContent>
                        <TrackComponent
                            tcuCode={showMapItem.status.tcu_code}
                            onClose={() => setShowTrackMap(false)}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setShowTrackMap(false)}>閉じる</Button>
                    </DialogActions>
                </Dialog>
            )}
        </TableContainer>
    );
}

export default ResultsTable;
