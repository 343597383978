import { createTheme } from "@mui/material/styles";

const theme = createTheme({
    palette: {
        primary: {
            main: "#1976d2",
            contrastText: "#ffffff",
        },
        secondary: {
            main: "#eeeeee",
            contrastText: "#1976d2",
        },
        normal: {
            main: "#808080",
            contrastText: "#ffffff",
        },
    },
});

export default theme;