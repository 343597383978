import React from 'react';
import axios from 'axios';
import { Box, Button } from '@mui/material';

const SimButtons = ({ tcuCode, fetchItems }) => {
    const buttonStyle = { margin: 1 };

    const handleDetailClick = async (simStatus) => {
        const apiUrl = process.env.REACT_APP_API_URL;
        try {
            await axios.put(`${apiUrl}/tcu/sim/${tcuCode}`, { simStatus });
            fetchItems();
        } catch (error) {
            console.error('Error updating SIM status:', error);
        }
    }

    return (
        <Box mt={2} textAlign="center">
            <Button
                variant="contained"
                color="primary"
                onClick={() => handleDetailClick(1)}
                sx={buttonStyle}
            >
                稼働
            </Button>
            <Button
                variant="contained"
                color="primary"
                onClick={() => handleDetailClick(0)}
                sx={buttonStyle}
            >
                休止
            </Button>
            <Button
                variant="contained"
                color="primary"
                onClick={() => handleDetailClick(2)}
                sx={buttonStyle}
            >
                解約
            </Button>
        </Box>
    );
};

export default SimButtons;
