import axios from 'axios';
import { useForm } from 'react-hook-form';
import { Box, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import RenderTextField from '../../components/forms/render-text-field';

const RenterModal = ({ item, onClose }) => {
    const renter = item;
    const { handleSubmit, control, formState: { errors } } = useForm({
        defaultValues: {
            renterName: renter ? renter.renter_name : '',
            phoneNumber: renter ? renter.phone_number : '',
            zipCode: renter ? renter.zip_code : '',
            address: renter ? renter.address : '',
            address2: renter ? renter.address2 : '',
        }
    });

    const apiUrl = process.env.REACT_APP_API_URL;

    const onSubmit = async (data) => {
        const entryData = {
            renterName: data.renterName,
            phoneNumber: data.phoneNumber,
            zipCode: data.zipCode,
            address: data.address,
            address2: data.address2,
        };

        try {
            renter ? await axios.put(`${apiUrl}/renters/${item.renter_code}`, entryData) // 編集
                : await axios.post(`${apiUrl}/renters`, entryData); // 登録
            onClose(true);
        } catch (error) {
            console.error('Error renter modal:', error);
        }
    };

    const renderTextField = (name, label, rules, required=false) => (
        <RenderTextField
            name={name}
            label={label}
            control={control}
            rules={rules}
            errors={errors}
            required={required}
        />
    );

    return (
        <Dialog open onClose={() => onClose(false)} fullWidth>
            <DialogTitle sx={{ mx: 2 }}>代理店{renter ? '編集' : '登録'}</DialogTitle>
            <DialogContent sx={{ mx: 2 }}>
                <Box component="form" onSubmit={handleSubmit(onSubmit)}>
                    {renderTextField('renterName', '代理店名', {
                        required: '代理店名を入力してください',
                        maxLength: { value: 60, message: '代理店名は60文字以内で入力してください' }
                    }, true)}
                    {renderTextField('phoneNumber', '電話番号', {
                        minLength: { value: 10, message: '電話番号は10桁以上で入力してください' },
                        maxLength: { value: 15, message: '電話番号は15桁以内で入力してください' },
                        pattern: { value: /^[0-9]+$/, message: '電話番号は半角数字のみ有効です' }
                    })}
                    {renderTextField('zipCode', '郵便番号', {
                        pattern: { value: /^[0-9]{3}-[0-9]{4}$/, message: '入力形式が正しくありません' }
                    })}
                    {renderTextField('address', '住所1', {
                        maxLength: { value: 100, message: '住所1は100文字以内で入力してください' }
                    })}
                    {renderTextField('address2', '住所2', {
                        maxLength: { value: 100, message: '住所2は100文字以内で入力してください' }
                    })}
                </Box>
            </DialogContent>
            <DialogActions sx={{ m: 1 }}>
                <Button onClick={handleSubmit(onSubmit)} variant="contained" color="primary">
                    {renter ? '確定' : '登録'}
                </Button>
                <Button onClick={() => onClose(false)}>キャンセル</Button>
            </DialogActions>
        </Dialog>
    );
};

export default RenterModal;
