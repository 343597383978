import React from 'react';
import { useLocation } from 'react-router-dom';
import CarModal from '../modals/car-modal';
import CommonManagement from '../../components/common-management';
import Header from '../../components/header';

function CarManagement() {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search); // クエリパラメータをパース
    const isEngineCutOn = searchParams.get('engCutOn') === 'true'; // engineCutOnの値を取得

    return (
        <>
            <Header current="車両管理" />
            <CommonManagement
                endpoint={["/cars"]}
                dropdownOptions={[
                    { value: '4-digitFrameNumber', label: '車台番号(下4桁)' },
                    { value: 'frameNumber', label: '車台番号(全一致)' },
                ]}
                tableHeaders={['車台番号', 'メーカー', '車種', '初度登録年月', 'ナンバー', '車検日', 'ボディーカラー', '始動方法', '賃借人名', '取付車載器のIMEI']}
                tableRowData={(car) => [
                    car.frame_number || '未登録',
                    car.car_maker || '未登録',
                    car.car_name || '未登録',
                    car.car_year || '未登録',
                    car.car_number || '未登録',
                    car.car_inspection_date || '未登録',
                    car.car_color || '未登録',
                    car.start_method === 0 ? 'シリンダー' : car.start_method === 1 ? 'プッシュ' : '未登録',
                    car.userName || '未登録',
                    car.imei || '未登録',
                ]}
                DetailModal={CarModal}
                approvalFlg="1"
                buttonType="management"
                option={isEngineCutOn ? 'isEngCutOn' : false}
            />
        </>
    );
}


export default CarManagement;
