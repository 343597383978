import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Box, Dialog, DialogTitle, DialogContent, DialogActions, Button, Paper, Typography, Alert } from '@mui/material';
import { useForm, FormProvider } from 'react-hook-form';
import CarForm from '../../components/forms/car-form';
import UserForm from '../../components/forms/user-form';
import transformCarData from '../../utils/forms/transform-car-data';
import transformUserData from '../../utils/forms/transform-user-data';

const UnapprovedModal = ({ item, onClose }) => {
    const [alert, setAlert] = useState({ show: false, message: '' });
    const navigate = useNavigate();
    const apiUrl = process.env.REACT_APP_API_URL;
    var [carYearYearString, carYearMonthString] = item?.car_year?.split('-') || ['', ''];
    carYearMonthString = carYearMonthString ? parseInt(carYearMonthString, 10) : '';
    const methods = useForm({
        defaultValues: {
            userName: item?.user_name || '',
            userKana: item?.user_kana || '',
            phoneNumber: item?.phone_number || '',
            mailAddress: item?.mail_address || '',
            zipCode: item?.zip_code || '',
            address: item?.address || '',
            address2: item?.address2 || '',
            renterCode: item?.renter_code || '',
            carMaker: item?.car_maker || '',
            carName: item?.car_name || '',
            carYear_gengou: false,
            carYear_year: carYearYearString,
            carYear_month: carYearMonthString,
            frameNumber: item?.frame_number || '',
            carNumber: item?.car_number || '',
            carInspectionDate_gengou: false,
            carInspectionDate_year: item?.car_inspection_date ? new Date(item.car_inspection_date).getFullYear() : '',
            carInspectionDate_month: item?.car_inspection_date ? new Date(item.car_inspection_date).getMonth() + 1 : '',
            carInspectionDate_day: item?.car_inspection_date ? new Date(item.car_inspection_date).getDate() : '',
            carColor: item?.car_color || '',
            startMethod: item?.start_method || null,
            tcuCode: item?.status.tcu_code || null,
        }
    });
    const { handleSubmit } = methods;

    const onSubmit = async () => {
        const data = methods.getValues();

        try {
            const carData = transformCarData(data);
            const userData = transformUserData(data);
            userData.frameNumber = carData.frameNumber;
            userData.renterCode = carData.renterCode;
            if (!item) {
                carData.approvalFlg = '0';
                userData.approvalFlg = '0';
            }

            // 車両情報のエントリー/編集            
            const carResponse = item 
                ? await axios.put(`${apiUrl}/cars/${item.car_code}`, carData) // 編集
                : await axios.post(`${apiUrl}/cars`, carData); // エントリー

            if (carResponse.data.message === 'frame number already exists') {
                setAlert({ show: true, message: 'この車台番号は既に登録されています。' });
                return;
            }
            
            // 賃借人情報のエントリー/編集
            item ? await axios.put(`${apiUrl}/users/${item.user_code}`, userData) // 編集
                : await axios.post(`${apiUrl}/users`, userData); // エントリー

            navigate('/unapproved');
        } catch (error) {
            setAlert({ show: true, message: '送信エラーが発生しました。' });
            console.error('Error during submission:', error);
        }
    };

    return (
        <Dialog open onClose={() => onClose(false)} fullWidth maxWidth="lg">
            <DialogTitle>車両・賃借人エントリー</DialogTitle>
            <DialogContent>
                <FormProvider {...methods}>
                    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
                        <Box display="flex" gap={2}> 
                            <Paper sx={{ flex: 1, padding: 2, border: '1px solid', borderColor: 'grey.400'}}> 
                                <Typography variant="h6" gutterBottom>車両情報</Typography>
                                <CarForm car={item} />
                            </Paper>
                            <Paper sx={{ flex: 1, padding: 2, border: '1px solid', borderColor: 'grey.400'}}> 
                                <Typography variant="h6" gutterBottom>賃借人情報</Typography>
                                <UserForm />
                            </Paper>
                        </Box>

                        {alert.show && (
                            <Alert severity="error" onClose={() => setAlert({ show: false })}>
                                {alert.message}
                            </Alert>
                        )}
                    </Box>
                </FormProvider>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSubmit(onSubmit)} variant="contained" color="primary">
                    {item ? '確定' : 'エントリー'}
                </Button>
                <Button onClick={() => onClose(false)}>キャンセル</Button>
            </DialogActions>
        </Dialog>
    );
};

export default UnapprovedModal;
