import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box, Typography } from '@mui/material';
import { GoogleMap, MarkerF, InfoWindowF } from '@react-google-maps/api';

const LastLocationComponent = ({ car, onClose }) => {
    const [isShownInfoWindow, setIsShownInfoWindow] = useState(false);

    if (!car.status) return null;
    const position = { lat: car.status.latitude, lng: car.status.longitude };

    return(
        <Dialog open onClose={onClose} fullWidth maxWidth="lg">
            <DialogTitle>最後に取得した位置情報</DialogTitle>
            <DialogContent>
                <GoogleMap
                    mapContainerStyle={{ height: '500px', width: '100%' }}
                    zoom={12}
                    center={position}
                >
                    <MarkerF
                        position={position}
                        onClick={() => setIsShownInfoWindow(true)}
                    />
                    { isShownInfoWindow && (
                        <InfoWindowF
                            position={position}
                            onCloseClick={() => setIsShownInfoWindow(false)}
                        >
                            <Box>
                                <Typography variant="body1">緯度: {car.status.latitude} </Typography>
                                <Typography variant="body1">経度: {car.status.longitude}</Typography>
                                <Typography variant="body1">
                                    エンジン: {car.status.acc ? 'ON' : 'OFF'}
                                </Typography>
                                <Typography variant="body1">日時: {car.status.tcu_date_time}</Typography>
                            </Box>
                        </InfoWindowF>
                    )}
                </GoogleMap>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>閉じる</Button>
            </DialogActions>
        </Dialog>
    );
}

export default LastLocationComponent;