import React from 'react';
import UnapprovedModal from '../modals/unapproved-modal';
import CommonManagement from '../../components/common-management';
import Header from '../../components/header';

function UnapprovedManagement() {
    return (
        <>
            <Header current="未承認管理" />
            <CommonManagement
                endpoint={["/cars", "/users"]}
                dropdownOptions={[
                    { value: '4-digitFrameNumber', label: '車台番号(下4桁)' },
                    { value: 'frameNumber', label: '車台番号(全一致)' },
                    { value: 'userName', label: '賃借人名' }
                ]}
                tableHeaders={[
                    '車台番号', 'メーカー', '車種', '初度登録年月', 'ナンバー', '車検日', 'ボディーカラー', '始動方法',
                    '賃借人名', '賃借人カナ', '電話番号', 'メールアドレス', '郵便番号', '住所'
                ]}
                tableRowData={(item) => [
                    item.frame_number || '未登録',
                    item.car_maker || '未登録',
                    item.car_name || '未登録',
                    item.car_year || '未登録',
                    item.car_number || '未登録',
                    item.car_inspection_date || '未登録',
                    item.car_color || '未登録',
                    item.start_method === 0 ? 'シリンダー' : item.start_method === 1 ? 'プッシュ' : '未登録',
                    item.user_name,
                    item.user_kana,
                    item.phone_number,
                    item.mail_address,
                    item.zip_code,
                    item.address + (item.address2 ? item.address2 : ''),
                ]}
                DetailModal={UnapprovedModal}
                approvalFlg="0"
                buttonType="management"
            />
        </>
    );
}

export default UnapprovedManagement;
