import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Box, Card, Paper, CardContent, Alert, Grid } from '@mui/material';
import { AuthContext } from '../../auth/AuthProvider';
import TrackComponent from '../../components/map/track-component';
import LoadingSpinner from '../../components/loading-spinner';
import LastLocationComponent from '../../components/map/last-location-component';
import RealImage from '../../images/リアル.png';
import SleepImage from '../../images/スリープ.png';
import DisableImage from '../../images/始動禁止.png';
import EnableImage from '../../images/始動許可.png';

const EngCutModal = ({ item, onClose }) => {
    const [car, setCar] = useState(item);
    const [engCutPreparationStatus, setEngCutPreparationStatus] = useState('');
    const [alert, setAlert] = useState({ show: false, message: '', severity: 'success' });
    const [dialogState, setDialogState] = useState({
        confirmation: null,
        isDone: null,
        notPrepared: null,
        preparation: null,
    });
    const [showMap, setShowMap] = useState(false);
    const { auth } = useContext(AuthContext);
    const apiUrl = process.env.REACT_APP_API_URL;

    const errorTypes = [
        { key: 'b_batt_error_1', label: 'バッテリー電圧低下異常' },
        { key: 'b_batt_error_2', label: 'バッテリー電圧急低下異常' },
        { key: 'internal_batt_error', label: '内部バッテリー異常' },
        { key: 'cellular_start_error', label: 'セルラー通信開始エラー回数' },
        { key: 'cellular_com_error', label: 'セルラー通信エラー回数' },
        { key: 'cellular_connect_error', label: 'セルラー接続エラー回数' },
        { key: 'gnss_circuit_com_error', label: 'GNSS回路通信エラー回数' },
        { key: 'gnss_pos_error', label: 'GNSS測位エラー' },
        { key: 'g_sensor_com_error', label: 'Gセンサー通信エラー' },
        { key: 'g_sensor_shock_detect', label: 'Gセンサー衝撃検出' },
        { key: 'thermistor_error', label: '周囲温度異常' },
        { key: 'cellular_temp_error', label: 'セルラー温度異常' },
        { key: 'tcu_setting_read_error', label: '車載器設定読込エラー' },
        { key: 'eeprom_com_error', label: 'EEPROM通信エラー' },
        { key: 'secure_storage_error', label: 'セキュアストレージ異常' },
        { key: 'remove_detect', label: '車載器取り外しの可能性' },
        { key: 'car_movement_detect', label: '自走せずにGPSが移動' }
    ];

    useEffect(() => {
        getPreparationStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // エンジンカット操作準備の状態を取得
    const getPreparationStatus = async () => {
        if (car.status === '未登録') return;
        try {
            const response = await axios.get(`${apiUrl}/tcu-settings/eng-cut-preparation/${car.status.tcu_code}`);
            const message = response.data.message;
            setEngCutPreparationStatus(message);
        } catch (error) {
            console.error('Error getting preparation status:', error);
            setAlert({ show: true, message: 'ステータス取得に失敗しました', severity: 'error' });
        }
    };

    // エンジンカットのON/OFFの処理
    const toggleEngCut = async (relay_req) => {
            try{
                const response = await axios.put(`${apiUrl}/tcu-settings/eng-cut/${car.status.tcu_code}`,{ 
                    relay_req, authorityLevel: auth.user.authorityLevel
                })
                await carStatusUpdate();
                setDialogState({ confirmation: null, notPrepared: null });
                getPreparationStatus();
                setAlert({ show: true, message: response.data.message, severity: 'success' });
            } catch (error) {
                console.error('Error updating eng cut status:', error);
                setAlert({ show: true, message: 'エンジンOFFに失敗しました', severity: 'error' });
            }
    };

    // エンジンカット操作準備の開始終了の処理
    const togglePreparation = async (preparationFlg) => {
        if (car.status === '未登録') return;
        try{
            await axios.put(`${apiUrl}/tcu-settings/eng-cut-preparation/${car.status.tcu_code}`, {
                preparationFlg, prev_ig_off_interval: car.setting.ig_off_interval
            })
            await carStatusUpdate();
            setDialogState({ preparation: null });
        } catch (error) {
            console.error('Error updating preparation status:', error);
            setAlert({ show: true, message: 'アクティブモード操作に失敗しました', severity: 'error' });
        }
    };    

    // エンジンカットボタンの処理
    const handleEngCut = (relay_req) => {
        if (car.status === '未登録') return;
        else {
            let message;
            if (relay_req !== car.status.eng_cut_relay && relay_req === car.setting.relay_req) {
                // 既にエンジンカットONまたはOFFの操作が行われている場合のポップアップ
                message = relay_req === 1 ? '既にエンジンOFFにする操作が行われています' 
                    : '既にエンジンONにする操作が行われています';
                setDialogState({ isDone: message });
            } else if (relay_req === car.status.eng_cut_relay && relay_req === car.setting.relay_req) {
                // 既にエンジンカットONまたはOFF状態の場合のポップアップ
                message = relay_req === 1 ? '既にエンジンOFF中です'
                    : '既にエンジンON中です';
                setDialogState({ isDone: message });
            } else if (car.setting.last_relay_req_authority_level > auth.user.authorityLevel && car.status.eng_cut_relay !== car.setting.relay_req) {
                // 権限が足りない場合のポップアップ
                message = relay_req === 1 ? 'エンジンOFFにする権限がありません' 
                    : 'エンジンONにする権限がありません';
                setDialogState({ isDone: message });


            } else {
                getPreparationStatus();
                if (engCutPreparationStatus === 'アクティブモード中') {
                    // エンジンカット操作準備が完了している場合のポップアップ
                    setDialogState({ confirmation: relay_req === 1 ? "OFF" : "ON" });
                } else {
                    // エンジンカット操作準備が行われていない場合のポップアップ
                    setDialogState({ notPrepared: relay_req === 1 ? "OFF" : "ON" });
                }
            }
        }
    };

    const handlePreparation = async (preparationFlg) => {
        getPreparationStatus();
        if (preparationFlg === "start" && engCutPreparationStatus === 'アクティブモード中') {
            setAlert({ show: true, message: '既にアクティブモードです', severity: 'error' });
        } else if (preparationFlg === "start" && engCutPreparationStatus.includes('アクティブモード準備中')) {
            setAlert({ show: true, message: '既にアクティブモード準備中です', severity: 'error' });
        } else if (preparationFlg === "end" && engCutPreparationStatus === 'スリープモード中') {
            setAlert({ show: true, message: '既にスリープモードです', severity: 'error' });
        } else if (preparationFlg === "end" && engCutPreparationStatus.includes('スリープモード準備中')) {
            setAlert({ show: true, message: '既にスリープモード準備中です', severity: 'error' });
        } else {
            setDialogState({ preparation: preparationFlg });
        }
    };

    // 車両情報の更新
    const carStatusUpdate = async () => {
        try{
            const response = await axios.get(`${apiUrl}/cars`, { 
                params: { target: "code", value: car.car_code, loginUser: null, approvalFlg: "1" }
            })      
            setCar(response.data[0]);
            getPreparationStatus();
            setAlert({ show: true, message: '車両情報を更新しました', severity: 'success' });
        } catch (error) {
            console.error('Error updating car status:', error);
        }
    };

    if (auth.isLoading) {
        return <LoadingSpinner />;
    }

    return (
        <Dialog open onClose={() => onClose(false)} maxWidth={false}>
            <DialogTitle align="center">車両の詳細</DialogTitle>
            <DialogContent dividers>
                <Box display="flex" flexDirection="column" gap={2}>
                    <Card sx={{backgroundColor: "#EEEEEE"}}>
                        <CardContent>
                            <Typography variant="h6" gutterBottom>基本情報</Typography>
                            <Typography variant="body1">車台番号: {car.frame_number}</Typography>
                            <Typography variant="body1">車載器番号: {car.setting.tcu_code || '未登録'}</Typography>
                            <Typography variant="body1">賃借人: {car.userName}</Typography>
                        </CardContent>
                    </Card>
                    <Card sx={{backgroundColor: "#EEEEEE"}}>
                        <CardContent>
                            <Typography variant="h6" gutterBottom>状態情報</Typography>
                            {car.status === '未登録' ? (
                                <Typography variant="body1">車載器からの通信なし</Typography>
                            ) : (
                            <>
                                <Typography variant="body1">車載器状態: {engCutPreparationStatus}</Typography>
                                <Typography variant="body1">
                                    エンジンOFF/ON:  
                                    {(() => {
                                        switch (car.status.eng_cut_relay) {
                                            case 0:
                                                return ' エンジンON';
                                            case 1:
                                                return ' エンジンOFF';
                                            case 2:
                                                return ' 不定';
                                            default:
                                                return ' 不明';
                                        }
                                    })()}
                                </Typography>
                                <Typography variant="body1">ACC状態: {car.status.acc ? 'ON' : 'OFF'}</Typography>
                                <Typography variant="body1">バッテリー電圧値: {car.status.b_batt} [V]</Typography>
                                <Typography variant="body1">TCU内蔵電池電圧値: {car.status.internal_batt} [V]</Typography>
                                <Typography variant="body1">
                                    最後に取得できた位置情報: 
                                {!car.status.latitude || !car.status.longitude ? (
                                    ' 未取得'
                                ) : (
                                    <Box display={'flex'}>
                                        <Button 
                                            variant="text" 
                                            color="primary" 
                                            style={{ marginLeft: 5, textDecoration: 'underline', padding: 0, minWidth: 'unset', textTransform: 'none' }} 
                                            onClick={() => setShowMap(true)}
                                        >
                                            Googleマップで表示
                                        </Button>
                                        <Typography variant="body1" sx={{marginLeft: 1}}>
                                            (取得時間：{new Date(car.status.tcu_date_time).toLocaleString()})
                                        </Typography>
                                    </Box>
                                )}
                                </Typography>
                                <Paper sx={{ marginY: 1, padding: 1 }}>
                                    <Typography variant="body1">位置情報の軌跡: </Typography>
                                    <TrackComponent tcuCode={car.status.tcu_code} />
                                </Paper>
                                <Typography variant="body1">データ取得時間: {car.status.tcu_date_time}</Typography>
                            </>
                            )}
                        </CardContent>
                    </Card>

                    <Grid container spacing={2} justifyContent="center" sx={{ maxWidth: 500, margin: "0 auto" }}>
                        <Grid container item spacing={2} justifyContent="center">
                            <Grid item xs={6}>
                                <Box
                                    component="img"
                                    src={RealImage}
                                    alt="アクティブモード開始"
                                    sx={{ width: "50%", cursor: "pointer", display: "block", margin: "0 auto" }}
                                    onClick={() => handlePreparation("start")}
                                />
                                <Typography align="center">アクティブモード開始</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Box
                                    component="img"
                                    src={DisableImage}
                                    alt="エンジンOFF"
                                    sx={{ width: "50%", cursor: "pointer", display: "block", margin: "0 auto" }}
                                    onClick={() => handleEngCut(1)}
                                />
                                <Typography align="center">エンジンOFF</Typography>
                            </Grid>
                        </Grid>

                        <Grid container item spacing={2} justifyContent="center">
                            <Grid item xs={6}>
                                <Box
                                    component="img"
                                    src={SleepImage}
                                    alt="スリープモード開始"
                                    sx={{ width: "50%", cursor: "pointer", display: "block", margin: "0 auto" }}
                                    onClick={() => handlePreparation("end")}
                                />
                                <Typography align="center">スリープモード開始</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Box
                                    component="img"
                                    src={EnableImage}
                                    alt="エンジンON"
                                    sx={{ width: "50%", cursor: "pointer", display: "block", margin: "0 auto" }}
                                    onClick={() => handleEngCut(0)}
                                />
                                <Typography align="center">エンジンON</Typography>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Box display="flex" justifyContent="center">
                        <Button variant="contained" color="primary" onClick={() => carStatusUpdate()}>
                            更新
                        </Button>
                    </Box>

                    {alert.show && (
                        <Alert severity={alert.severity} onClose={() => setAlert({ show: false })}>
                            {alert.message}
                        </Alert>
                    )}

                    {auth.user.authorityLevel === 4 && (
                        <Card sx={{backgroundColor: "#EEEEEE"}}>
                            <CardContent>
                                <Typography variant="h6" gutterBottom>エラー情報</Typography>
                                {car.error === '未登録' ? (
                                    <Typography variant="body1">エラー情報なし</Typography>
                                    ) : (
                                    errorTypes.map((error) => (
                                        <Typography key={error.key} variant="body1">
                                            {error.label}: 
                                            {error.label.endsWith('回数') 
                                                ? car.error[error.key]
                                                : <span style={{ color: car.error[error.key] ? 'red' : 'inherit' }}>
                                                    {car.error[error.key] ? '異常あり' : '正常'}
                                                </span>
                                            }           
                                        </Typography>
                                    ))
                                )}
                            </CardContent>
                        </Card>
                    )}
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onClose(false)}>閉じる</Button>
            </DialogActions>

            {dialogState.confirmation && (
                <Dialog open onClose={() => Dialog(null)}>
                    <DialogTitle>確認</DialogTitle>
                    <DialogContent>
                        <Typography>
                            本当にエンジン{dialogState.confirmation}にしますか？
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => toggleEngCut(dialogState.confirmation === "OFF" ? 1 : 0)}>
                            はい
                        </Button>
                        <Button onClick={() => setDialogState({confirmation: null})}>
                            いいえ
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
            {dialogState.isDone && (
                <Dialog open onClose={() => setDialogState({isDone: null})}>
                <DialogTitle>エラー</DialogTitle>
                <DialogContent>
                    <Typography>{dialogState.isDone}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDialogState({isDone: null})}>元の画面に戻る</Button>
                </DialogActions>
            </Dialog>
            )}
            {dialogState.notPrepared && (
                <Dialog open onClose={() => setDialogState({notPrepared: null})}>
                    <DialogTitle>スリープモード中です</DialogTitle>
                    <DialogContent>
                        <Typography>スリープモード中です</Typography>
                        <Typography>
                            このままエンジンOFF操作を続けると予期しないタイミングで
                            エンジン{dialogState.notPrepared}になる可能性があります
                        </Typography>
                        <Typography>
                            このままエンジン{dialogState.notPrepared}にしますか？
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => toggleEngCut(dialogState.notPrepared === "OFF" ? 1 : 0)}>
                            はい
                        </Button>
                        <Button onClick={() => setDialogState({notPrepared: null})}>
                            いいえ
                        </Button>
                        {engCutPreparationStatus === 'スリープモード中です' && (
                        <Button onClick={() => {
                            setDialogState({preparation: "start", notPrepared: null});
                            }}>
                            アクティブモードにする
                        </Button>
                        )}
                    </DialogActions>
                </Dialog>
            )}
            {dialogState.preparation && (
                <Dialog open onClose={() => setDialogState({preparation: null})}>
                    <DialogTitle>アクティブモード/スリープモード</DialogTitle>
                    <DialogContent>
                        <Typography>
                            {dialogState.preparation === "start" ? 'アクティブモード' : 'スリープモード'} にします
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => togglePreparation(dialogState.preparation === "start" ? true : false)}>
                            はい
                        </Button>
                        <Button onClick={() => setDialogState({preparation: null})}>
                            キャンセル
                        </Button>
                    </DialogActions>
                </Dialog>    
            )}

            {showMap && <LastLocationComponent car={car} onClose={() => setShowMap(false)} />}

        </Dialog>
    );
}

export default EngCutModal;
