import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import { Paper, Grid, MenuItem, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Select, InputLabel } from '@mui/material';

 // startから始まるcount分の配列を生成
const generateOptions = (count, start = 1) => [...Array(count)].map((_, i) => i + start);
// 現在の年からcount年分の配列を生成
const generateYears = (currentYear, count = 100) => generateOptions(count, currentYear - count + 1).reverse();
const months = generateOptions(12);
const days = generateOptions(31);

const DatePickerDropdown = ({ control, setValue, name, label }) => {
    const [dateType, setDateType] = useState('seireki');  // 初期値は西暦
    const [wareki, setWareki] = useState('reiwa');  // 和暦の元号の初期値
    const [years, setYears] = useState(generateYears(new Date().getFullYear()));

    const updateWarekiYears = (gengou) => {
        const currentYear = new Date().getFullYear() -2019 + 1; // 今年の和暦年数を取得
        const gengouYears = {
            reiwa: name === 'carInspectionDate' ? currentYear + 5 : currentYear,
            'heisei': 31,
            'showa': 64
        };
        setWareki(gengou);
        setYears(generateOptions(gengouYears[gengou]).reverse());
    }

    // 和暦の元号選択
    const handleWarekiChange = (event) => {
        const gengou = event.target.value;
        updateWarekiYears(gengou);
    };

    // 西暦と和暦のラジオボタンによる切り替え
    const handleDateTypeChange = (event) => {
        const type = event.target.value;
        setDateType(type);
        if (type === 'seireki') {
            name === 'carInspectionDate' ? setYears(generateYears(new Date().getFullYear() + 5))
            : setYears(generateYears(new Date().getFullYear()));
        } else {
            setValue(`${name}_gengou`, wareki);
            updateWarekiYears(wareki); // 和暦の場合は元号に基づく年を再設定
        }
    };

    return (
        <Paper elevation={0} sx={{ padding: 1.5, marginTop: 2, border: '1px solid #ccc' }}>
            <FormControl component="fieldset">
                <FormLabel component="legend">{label}</FormLabel>
                <RadioGroup row value={dateType} onChange={handleDateTypeChange}>
                    <FormControlLabel value="seireki" control={<Radio />} label="西暦" />
                    <FormControlLabel value="wareki" control={<Radio />} label="和暦" />
                </RadioGroup>
            </FormControl>
            <Grid container spacing={2}>
                {dateType === 'wareki' && (
                    <Grid item xs={3}>
                        <FormControl fullWidth size="small">
                            <InputLabel>元号</InputLabel>
                            <Controller
                                name={`${name}_gengou`}
                                control={control}
                                render={({ field }) => (
                                    <Select 
                                        {...field}
                                        onChange={(e) => {
                                            field.onChange(e);  
                                            handleWarekiChange(e);  
                                        }}
                                    >
                                        <MenuItem value="reiwa">令和</MenuItem>
                                        <MenuItem value="heisei">平成</MenuItem>
                                        <MenuItem value="showa">昭和</MenuItem>
                                    </Select>
                                )}
                            /> 
                        </FormControl>
                    </Grid>
                )}
                <Grid item xs={dateType === 'wareki' ? 3 : 6}>
                    <Controller
                        name={`${name}_year`}
                        control={control}
                        render={({ field }) => (
                            <FormControl fullWidth size="small">
                                <InputLabel>{dateType === 'seireki' ? '年 (西暦)' : '年 (和暦)'}</InputLabel>
                                <Select {...field}>
                                    {years.map((year) => (
                                        <MenuItem key={year} value={year}>
                                            {year}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}
                    />
                </Grid>
                <Grid item xs={dateType === 'wareki' ? 3 : 3}>
                    <Controller
                        name={`${name}_month`}
                        control={control}
                        render={({ field }) => (
                            <FormControl fullWidth size="small">
                                <InputLabel>月</InputLabel>
                                <Select {...field}>
                                    {months.map((month) => (
                                        <MenuItem key={month} value={month}>
                                            {month}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}
                    />
                </Grid>
                {name === 'carInspectionDate' && 
                    <Grid item xs={dateType === 'wareki' ? 3 : 3}>
                        <Controller
                            name={`${name}_day`}
                            control={control}
                            render={({ field }) => (
                                <FormControl fullWidth size="small">
                                    <InputLabel>日</InputLabel>
                                    <Select {...field}>
                                        {days.map((day) => (
                                            <MenuItem key={day} value={day}>
                                                {day}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            )}
                        />
                    </Grid>
                }
            </Grid>
        </Paper>
    );
};

export default DatePickerDropdown;
