const connectCarWithUser = (carList, userList) => {
    return carList.map(car => {
        const matchingUser = userList.find(user => user.user_code === car.user_code);
        if (matchingUser) {
            return { ...car, ...matchingUser };
        }
        return car;
    });
}

export default connectCarWithUser;
