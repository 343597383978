import React from 'react';
import RenterModal from '../modals/renter-modal';
import CommonManagement from '../../components/common-management';
import Header from '../../components/header';

function RenterManagement() {
    return (
        <>
            <Header current="代理店管理" />
            <CommonManagement
                endpoint={["/renters"]}
                dropdownOptions={[{ value: 'renterName', label: '代理店名' }]}
                tableHeaders={['代理店名', '電話番号', '郵便番号', '住所']}
                tableRowData={(renter) => [
                    renter.renter_name,
                    renter.phone_number || '未登録',
                    renter.zip_code || '未登録',
                    renter.address ? renter.address + (renter.address2 ? renter.address2 : '') : '未登録',
                ]}
                DetailModal={RenterModal}
                buttonType="management"
            />
        </>
    );
}


export default RenterManagement;
