import React from 'react';
import { Controller } from 'react-hook-form';
import { TextField, Autocomplete, CircularProgress } from '@mui/material';

const RenderAutocompleteField = ({ name, label, control, options, ...props }) => (
    <Controller
        name={name}
        control={control}
        render={({ field }) => (
            <Autocomplete
                {...field}
                fullWidth
                size="small"
                options={options}
                loading={!options}
                loadingText={<CircularProgress size={16} />}
                getOptionLabel={(option) => option ? String(option) : ''}
                onChange={(event, value) => field.onChange(value ? value : '')}
                noOptionsText="見つかりません"
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={label}
                        margin="normal"
                        {...props}
                    />
                )}
            />
        )}
    />
);

export default  RenderAutocompleteField;
