import React, { useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { Box } from '@mui/material';
import { AuthContext } from '../../auth/AuthProvider';
import LoadingSpinner from '../../components/loading-spinner';
import RenderTextField from '../../components/forms/render-text-field';

const UserModal = () => {
    const { auth } = useContext(AuthContext);
    const { control, formState: { errors } } = useFormContext();

    const renderTextField = (name, label, rules, required=false) => (
        <RenderTextField
            name={name}
            label={label}
            control={control}
            rules={rules}
            errors={errors}
            required={required}
        />
    );

    if (auth.isLoading) {
        return <LoadingSpinner />;
    }

    return (
        <Box>
            {renderTextField('userName', '氏名', {
                required: '入力してください' 
            }, true)}
            {renderTextField('userKana', '氏名（フリガナ）', {
                required: '入力してください',
                maxLength: { value: 60, message: '60文字以内で入力してください' }
            }, true)}
            {renderTextField('phoneNumber', '電話番号', {
                required: '入力してください',
                minLength: { value: 10, message: '10文字以上で入力してください' },
                maxLength: { value: 15, message: '15文字以内で入力してください' },
                pattern: { value: /^[0-9]*$/, message: '半角数字のみ有効です' }
            }, true)}
            {renderTextField('mailAddress', 'メールアドレス', {
                required: '入力してください',
                maxLength: { value: 255, message: '255文字以内で入力してください' },
                pattern: { value: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/, message: 'メールアドレスの形式が正しくありません' }
            }, true)}
            {renderTextField('zipCode', '郵便番号', {
                required: '入力してください',
                pattern: { value: /^[0-9]{3}-[0-9]{4}$/, message: '入力形式が正しくありません' }
            }, true)}
            {renderTextField('address', '住所１', {
                required: '入力してください',
                maxLength: { value: 100, message: '100文字以内で入力してください' }
            }, true)}
            {renderTextField('address2', '住所２（アパート等）', {
                maxLength: { value: 100, message: '100文字以内で入力してください' }
            })}
        </Box>
    );
}


export default UserModal;
