
// 和暦を西暦に変換
const convertWarekiToSeireki = (gengou, year) => {
    const gengouYearMap = {
        'reiwa': 2018,
        'heisei': 1988,
        'showa': 1925
    };
    return gengouYearMap[gengou] + year;
};

// 日付フォーマット整形
const formatDate = (year, month, day) => {
    if (!year || !month || (!day && day != null)) return null;
    const formattedMonth = String(month).padStart(2, '0');
    const formattedDay = day ? String(day).padStart(2, '0') : '';
    return day ? `${year}-${formattedMonth}-${formattedDay}` : `${year}-${formattedMonth}`;
};

const transformCarData = (data) => {
    const carYear_year = data.carYear_gengou 
        ? convertWarekiToSeireki(data.carYear_gengou, data.carYear_year) 
        : data.carYear_year;
    const carInspectionDate_year = data.carInspectionDate_gengou 
        ? convertWarekiToSeireki(data.carInspectionDate_gengou, data.carInspectionDate_year) 
        : data.carInspectionDate_year;

    const transformedData = {
        carMaker: data.carMaker,
        carName: data.carName,
        carYear: formatDate(carYear_year, data.carYear_month, null),
        frameNumber: data.frameNumber,
        carNumber: data.carNumber,
        carInspectionDate: formatDate(carInspectionDate_year, data.carInspectionDate_month, data.carInspectionDate_day),
        carColor: data.carColor,
        startMethod: data.startMethod,
        imei: data.imei,
        renterCode: data.renterCode.renter_code,
    };

    return transformedData;
};

export default transformCarData;