import React from 'react';
import TcuModal from '../modals/tcu-modal';
import CommonManagement from '../../components/common-management';
import Header from '../../components/header';

function TcuManagement() {
    const simStatusToText = (status) => {
        switch (status) {
            case 0:
                return '休止';
            case 1:
                return '稼働';
            case 2:
                return '解約';
            default:
                return '不明';
        }
    };

    return (
        <>
            <Header current="車載器管理"/>
            <CommonManagement
                endpoint={["/tcu"]}
                dropdownOptions={[
                    { value: 'imei', label: 'IMEI番号' },
                    { value: '4-digitFrameNumber', label: '取付車両の車台番号(下4桁)' },
                    { value: 'frameNumber', label: '取付車両の車台番号(全一致)' },
                ]}
                tableHeaders={
                    ['管理番号', 'IMEI番号', 'IPアドレス', 'バージョン番号', '電話番号', 'SIMの状態', '取付日', '保証期間', '取付車両の車台番号']
                }
                tableRowData={(tcu) => [
                    tcu.tcu_code,
                    tcu.imei,
                    tcu.ip_address,
                    tcu.tcu_sw_version || '未登録',
                    tcu.phone_number,
                    simStatusToText(tcu.sim_status),
                    tcu.install_day ? new Date(tcu.install_day).toLocaleDateString() : '未登録',
                    tcu.warranty_period ? new Date(tcu.warranty_period).toLocaleDateString() : '未登録',
                    tcu.frame_number,
                ]}
                DetailModal={TcuModal}
                buttonType="management"
                option="tcu"
            />
        </>
    );
}


export default TcuManagement;
