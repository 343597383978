import React from 'react';
import { Controller } from 'react-hook-form';
import { FormControl,  TextField, Autocomplete, CircularProgress } from '@mui/material';

const RenterSelect = ({ control, renters, loginUsersRenter, loginUser }) => {
    const isReadOnly = loginUser.authorityLevel === 1 || loginUser.authorityLevel === 2;
    const validationRules = !isReadOnly ? { required: '代理店を選択してください' } : {};

    return (
        <FormControl fullWidth size="small">
            <Controller
                name="renterCode"
                control={control}
                rules={validationRules}
                render={({ field, fieldState }) => (
                    isReadOnly ? (
                        <TextField
                            {...field}
                            label="代理店名"
                            value={loginUsersRenter.renter_name}
                            fullWidth
                            size="small"
                            margin="normal"
                            InputProps={{ readOnly: true }}
                        />
                    ) : (
                        <Autocomplete
                            {...field}
                            options={renters}
                            loading={!renters}
                            fullWidth
                            size="small"
                            loadingText={<CircularProgress size={16} />}
                            getOptionLabel={(option) => option ? option.renter_name : ''}
                            onChange={(event, value) => field.onChange(value ? value : '')}
                            noOptionsText="見つかりません"
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="代理店名"
                                    margin="normal"
                                    error={!!fieldState.error}
                                    helperText={fieldState.error ? fieldState.error.message : ''}
                                    required
                                />
                            )}
                        />
                    )
                )}
            />
        </FormControl>
    );
};

export default RenterSelect;
