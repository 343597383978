import React from 'react';
import CommonManagement from '../../components/common-management';
import EngCutModal from '../modals/eng-cut-modal';
import Header from '../../components/header';

function EngCutManagement() {
    return (
        <>
            <Header current="エンジンカットシステム" />
            <CommonManagement
                endpoint={["/cars"]}
                dropdownOptions={[
                    { value: '4-digitFrameNumber', label: '車台番号(下4桁)' },
                    { value: 'frameNumber', label: '車台番号(全一致)' },
                ]}
                tableHeaders={[
                    '車台番号', 
                    '賃借人', 
                ]}
                tableRowData={(car) => [
                    car.frame_number || '未登録',
                    car.userName || '未登録',
                ]}
                DetailModal={EngCutModal}
                approvalFlg="1"
                buttonType="setting"
                option="engCut"
            />
        </>
    );
}

export default EngCutManagement;
