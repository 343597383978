import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Box, FormControl, TextField, Button, Typography, Alert, Link } from '@mui/material'; 
import { AuthContext } from "./AuthProvider";
import { signIn } from 'aws-amplify/auth'
import axios from 'axios';
import ForgotPasswordModal from './forgot-password';

function Login() {
    const [mailAddress, setMailAddress] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const { login } = useContext(AuthContext);
    const [alert, setAlert] = useState({ show: false, message: '', severity: 'error', temporaryFlg: false });
    const [showModal, setShowModal] = useState(false);

    // フォームの送信時に呼び出される関数
    const handleSubmit = async (e) => {
        e.preventDefault(); // ページのリロードを防ぐ
        try {
            const signInResponse = await signIn({username: mailAddress, password: password})
            .catch(error => {
                if (error.message === 'Incorrect username or password.'){
                    setAlert({ 
                        show: true, 
                        message: 'メールアドレスまたはパスワードが正しくありません', 
                        severity: 'error',
                        temporaryFlg: false 
                    });
                } else if (error.message === 'Temporary password has expired and must be reset by an administrator.') {
                    setAlert({ 
                        show: true, 
                        message: 'パスワードの有効期限が切れています。', 
                        severity: 'error',
                        temporaryFlg: true 
                    });
                } else {
                    console.error('Login error:', error);
                }
            });

            if (signInResponse) {
                const { isSignedIn, nextStep } = signInResponse;
                if (isSignedIn) {
                    // ログインに成功していた場合、ユーザー情報をAuthProviderに渡し、ホーム画面に遷移
                    login(mailAddress);
                    navigate('/home');
                } else if (nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED') {
                    // 新しいパスワードの設定画面に遷移
                    navigate('/new-password', { state: { mailAddress } }); 
                } else {
                    console.error('Login error:', signInResponse);
                }
            }
        } catch (error) {
            console.error('Login error', error);
        }
    }

    const handleReset = () => {
        setShowModal(true);
    }

    const handleTemporaryReset = async (e) => {
        e.preventDefault();
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            await axios.post(`${apiUrl}/admins/reset-temporary-password`, { mailAddress });
            setAlert({ 
                show: true, 
                message: 'メールアドレスに新しい仮パスワードを送信しました。', 
                severity: 'success',
                temporaryFlg: false
            });
        } catch (error) {
            console.error('Error resetting temporary password:', error);
        }
    }

    return (
        <Container component="main" maxWidth="xs">
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                height="90vh"
            >
                <Typography variant="h4" style={{ marginBottom: '3rem' }}> エンジン停止システム </Typography>
                <Typography variant="h5" color="primary" > Login </Typography>
                <FormControl component="form" fullWidth onSubmit={handleSubmit}>
                    <TextField
                        margin="normal"
                        required
                        id="mailAddress"
                        label="メールアドレス"
                        name="mailAddress"
                        value={mailAddress}
                        onChange={(e) => setMailAddress(e.target.value)}
                    />
                    <TextField
                        margin="normal"
                        required
                        id="password"
                        label="パスワード"
                        name="password"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    {alert.show && (
                        <Alert severity={alert.severity} onClose={() => setAlert({ show: false })}>
                            {alert.message}
                            {alert.temporaryFlg && (
                                <Link 
                                    component="button" 
                                    variant="body2" 
                                    onClick={handleTemporaryReset} 
                                    style={{ marginLeft: '1rem' }}
                                >
                                    一時パスワードを再発行しますか？
                                </Link>
                            )}
                        </Alert>
                    )}
                    <Button 
                        type="submit" 
                        variant="contained" 
                        color="primary"  
                        sx={{ marginTop: 1, textTransform: 'none' }}
                    >
                        Login
                    </Button>
                </FormControl>
                <Link component="button" variant="body2" onClick={handleReset} style={{ marginTop: '1rem' }}>
                    パスワードをお忘れですか？
                </Link>
            </Box>
            { showModal &&
                <ForgotPasswordModal onClose={() => setShowModal(false)} initialMailAddress={mailAddress} />
            }
        </Container>
    );
}

export default Login;