import React, { useState, useContext } from 'react';
import { TextField, Button, Box, Typography, Container } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { AuthContext } from "./AuthProvider";
import { confirmSignIn } from 'aws-amplify/auth';
import { useForm, Controller } from 'react-hook-form';

const NewPasswordForm = () => {
    const location = useLocation();
    const { mailAddress } = location.state || {};
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const { login } = useContext(AuthContext);
    const { handleSubmit, control, formState: { errors } } = useForm();

    const onSubmit = async (data) => {
        const { newPassword, confirmPassword } = data;
        if (newPassword !== confirmPassword) {
            setError('パスワードが一致しません');
            return;
        }
        try {
            const userAttributes = { };
            await confirmSignIn({
                challengeResponse: newPassword,
                options: {
                    userAttributes
                }
            });
            login(mailAddress); // ログイン情報をAuthProviderに渡す
            navigate('/home'); // ログイン成功時にホーム画面に遷移
        } catch (error) {
            console.error('Error confirming sign in:', error);
            setError('パスワードの設定に失敗しました');
        }
    };

    return (
        <Container component="main" maxWidth="xs">
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                height="90vh"
            >
                <Typography variant="h6" gutterBottom>
                    新しいパスワードの設定
                </Typography>
                <Box component="form" onSubmit={handleSubmit(onSubmit)}>
                    <Controller
                        name="newPassword"
                        control={control}
                        rules={{
                            required: '入力してください',
                            minLength: { value: 8, message: '8文字以上で入力してください' },
                            maxLength: { value: 100, message: '100文字以内で入力してください' },
                            pattern: {
                                value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[A-Za-z0-9!@#$%^&*]*$/,
                                message: 'パスワードは、少なくとも1文字の小文字、大文字、特殊文字、数字を含める必要があります'
                            }
                        }}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                label="新しいパスワード"
                                fullWidth
                                size="small"
                                margin="normal"
                                type="password"
                                error={!!errors.newPassword}
                                helperText={errors.newPassword ? errors.newPassword.message : ''}
                                required
                            />
                        )}
                    />
                    <Controller
                        name="confirmPassword"
                        control={control}
                        rules={{
                            required: '入力してください',
                        }}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                label="新しいパスワード（確認用）"
                                fullWidth
                                size="small"
                                margin="normal"
                                type="password"
                                error={!!errors.confirmPassword}
                                helperText={errors.confirmPassword ? errors.confirmPassword.message : ''}
                                required
                            />
                        )}
                    />
                    {error && <Typography color="error">{error}</Typography>}
                    <Button onClick={handleSubmit(onSubmit)} variant="contained" color="primary" fullWidth sx={{marginTop: 2}}>
                        パスワードを設定
                    </Button>
                </Box>
            </Box>
        </Container>
    );
};

export default NewPasswordForm;
