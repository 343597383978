import React, { createContext, useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { fetchAuthSession, getCurrentUser } from "aws-amplify/auth";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState({ isLoading: true, user: null });

    const getAuthenticatedUser = useCallback(async () => {
        try {
            const { signInDetails } = await getCurrentUser();
            const { tokens: session } = await fetchAuthSession();
            return { session, signInDetails };
        } catch (error) {
            console.error('Error getting authenticated user:', error);
            return null;
        }
    },[]);

    const getUserDetails = useCallback(async (mailAddress) => {
        const apiUrl = process.env.REACT_APP_API_URL;
        try {
            const response = await axios.post(`${apiUrl}/auth`, { mailAddress });
            const user = response.data.user;
            return user;
        } catch (error) {
            console.error('Error fetching user details:', error);
            return null;
        }
    },[]);

    useEffect(() => {
        setAuth({ isLoading: true });
        const checkAuth = async () => {
            try {
                const { signInDetails } = await getAuthenticatedUser();
    
                if (signInDetails) {
                    const user = await getUserDetails(signInDetails.loginId);
                    setAuth({ isLoading: false, user: user });
                } else {
                    setAuth({ isLoading: false, user: null });
                }
            } catch (error) {
                console.error('Error getting authenticated user:', error);
                setAuth({ isLoading: false, user: null });
            }
        }
        checkAuth();
    }, [getAuthenticatedUser, getUserDetails]); 

    const login = async (mailAddress) => {
        setAuth({ isLoading: true });
        const user = await getUserDetails(mailAddress);
        setAuth({ isLoading: false, user: user });
    };

    const logout = () => {
        setAuth({ isLoading: true,  user: null });
    };

    return (
        <AuthContext.Provider value={{ auth, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};