const transformUserData = (data) => {
    const transformedData = {
        userName: data.userName,
        userKana: data.userKana,
        phoneNumber: data.phoneNumber,
        mailAddress: data.mailAddress,
        zipCode: data.zipCode,
        address: data.address,
        address2: data.address2,
    };

    return transformedData;
};

export default transformUserData;