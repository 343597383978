import React from 'react';
import { Dialog, DialogActions, DialogTitle, Button } from '@mui/material';


const ConfirmModal = ({ message, onConfirm, onClose }) => {
    return (
        <Dialog open onClose={onClose}>
            <DialogTitle sx={{ mx: 2, textAlign: 'center' }}>{message}</DialogTitle>
            <DialogActions sx={{ m: 1, display: 'flex', justifyContent: 'center' }}>
                <Button onClick={onConfirm} color="primary" variant="contained">確定</Button>
                <Button onClick={onClose} color="primary" variant="outlined">キャンセル</Button>
            </DialogActions>
        </Dialog>
    );
}

export default ConfirmModal;
