import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Paper, Box, Typography, CircularProgress } from '@mui/material';

const DashboardItems = ({ title, count, unit, isLoading }) => {
    const navigate = useNavigate();

    const link = {
        '車両': '/car-management',
        '賃借人': '/user-management',
        '未承認車両': '/unapproved',
        '未承認賃借人': '/unapproved',
        'エンジンOFF車両': '/eng-cut-search?engCutOn=true',
        'エラー車両': '/error-management',
    }

    const handleClick = () => {
        navigate(link[title]);
    }

    return (
        <Grid item xs={2}>
            <Paper 
                elevation={3} 
                sx={{ 
                    padding: 2, 
                    textAlign: 'center', 
                    backgroundColor: '#f8f8f8',
                    cursor: 'pointer', // カーソルをポインターに設定
                    transition: 'background-color 0.3s ease', // 背景色のトランジション効果
                    '&:hover': {
                        backgroundColor: '#e0e0e0', // ホバー時の背景色
                    },
                }}
                onClick={() => handleClick(title)}    
            >
                <Typography variant="h6" sx={{ marginBottom: 1 }}>{title}</Typography>
                    {isLoading ? (
                        <CircularProgress />
                    ) : (
                    <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'flex-end'}}>
                        <Typography variant="h4" sx={{ fontWeight: 'bold' }}>{count}</Typography>
                        <Typography variant="h5" sx={{ marginLeft: 0.5, paddingBottom: 0.3 }}>{unit}</Typography>
                    </Box>
                )}
            </Paper>
        </Grid>
    );
};

export default DashboardItems;