import React, { useState, useEffect, useContext } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import axios from 'axios';
import { Box, Paper, Typography, Radio, RadioGroup, FormLabel, FormControlLabel } from '@mui/material';
import { AuthContext } from '../../auth/AuthProvider';
import RenterSelect from '../../components/forms/renter-select';
import DatePickerDropdown from '../../components/forms/date-picker-dropdowns';
import LoadingSpinner from '../../components/loading-spinner';
import RenderTextField from '../../components/forms/render-text-field';
import RenderAutocompleteField from '../../components/forms/render-autocomplete-field';

const CarForm = ({ car }) => {
    const [renters, setRenters] = useState([]);
    const [renter, setRenter] = useState(car ? car.renter_code : '');
    const [tcuList, setTcuList] = useState([]);
    const { auth } = useContext(AuthContext);
    const loginUser = auth.user;
    const apiUrl = process.env.REACT_APP_API_URL;
    const { control, setValue, formState: { errors } } = useFormContext();
    
    // 代理店情報取得
    useEffect(() => {
        const fetchRenters = async () => {
            if (!loginUser) return;
            try {
                const response = await axios.get(`${apiUrl}/renters`, { params: { loginUser } })
                if (loginUser.authorityLevel === 1 || loginUser.authorityLevel === 2) {
                    setRenter(response.data);
                    setValue('renterCode', response.data.renter_code);
                } else {
                    setRenters(response.data);
                    // 編集時の代理店名の初期値設定
                    if (car) {
                        setValue('renterCode', response.data.find(renter => renter.renter_code === car.renter_code));
                    }
                }                    
            } catch (error) {
                console.error('Error fetching renters:', error);
            }
        }

        fetchRenters();
    }, [apiUrl, setValue, loginUser, car]);

    // 全車載器の管理番号を取得
    useEffect(() => {
        const getTcuList = async () => {
            try {
                const response = await axios.get(`${apiUrl}/tcu`,{ params: { target: null, value: null } });
                const sortList = (response.data
                    .map(tcu => tcu.imei)
                    .sort((a, b) => a - b));
                setTcuList(['未登録', ...sortList]);
            } catch (error) {
                console.error('Error during car fetch:', error);
            }
        };
        getTcuList();
    }, [apiUrl]);

    // 車両の始動方法の初期値設定
    useEffect(() => {
        if (car?.start_method !== undefined) {
            setValue('startMethod', car.start_method);
        }
    }, [car, setValue]);

    const renderTextField = (name, label, rules, required = false) => (
        <RenderTextField
            name={name}
            label={label}
            control={control}
            rules={rules}
            errors={errors}
            required={required}
        />
    );

    if (auth.isLoading) {
        return <LoadingSpinner />;
    }

    return (
        <Box>
            {renderTextField('carMaker', 'メーカー', {
                maxLength: { value: 100, message: '100文字以内で入力してください' }
            })}
            {renderTextField('carName', '車種', {
                required: '入力してください',
                maxLength: { value: 100, message: '100文字以内で入力してください' }
            }, true)}
            <DatePickerDropdown 
                control={control} 
                name="carYear" 
                label="初度登録年月"
                setValue={setValue}
            />
            {renderTextField('frameNumber', '車台番号', {
                required: '入力してください',
                maxLength: { value: 17, message: '17文字以内で入力してください' },
                pattern: { value: /^[ -~]*$/, message: '半角英数字のみ有効です' }
            }, true)}
            {renderTextField('carNumber', 'ナンバー', {
                minLength: { value: 7, message: '7文字以上で入力してください' },
                maxLength: { value: 13, message: '13文字以内で入力してください' }
            })}
            <DatePickerDropdown 
                control={control} 
                name="carInspectionDate" 
                label="車検日"
                setValue={setValue}
            />
            {renderTextField('carColor', 'ボディーカラー', {
                maxLength: { value: 20, message: '20文字以内で入力してください' }
            })}
            <Controller
                control={control}
                name="startMethod"
                rules={{ required: '選択してください' }}
                render={({ field, fieldState }) => (
                    <>
                        <Paper
                            elevation={0}
                            sx={{
                                padding: 1.5,
                                marginTop: 2,
                                border: '1px solid',
                                borderColor: fieldState.error ? 'error.main' : 'grey.400',
                            }}
                        >
                            <FormLabel component="legend">始動方法 *</FormLabel>
                            <RadioGroup {...field} row value={field.value}>
                                <FormControlLabel value="0" control={<Radio />} label="シリンダー" />
                                <FormControlLabel value="1" control={<Radio />} label="プッシュ" />
                            </RadioGroup>
                        </Paper>
                        {fieldState.error && (
                            <Typography color="error" variant="body2" sx={{ marginTop: 0.5, marginLeft: 1.5, fontSize: 12 }}>
                                {fieldState.error.message}
                            </Typography>
                        )}
                    </>
                )}
            />
            {car && (
                <RenderAutocompleteField
                    name="imei"
                    label="取付車載器のIMEI番号"
                    control={control}
                    options={tcuList}
                />
            )}
            <RenterSelect
                control={control}
                renters={renters}
                loginUsersRenter={renter}
                loginUser={loginUser}
            />
        </Box>
    );
};

export default CarForm;
