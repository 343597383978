import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Button, Typography, Box, Paper, CircularProgress } from '@mui/material';
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

function OtaFileSetting({setAlert}) {
    const [otaEnabledFile, setOtaEnabledFile] = useState('');
    const [uploadFile, setUploadFile] = useState('');
    const [loading, setLoading] = useState(false); // ローディング状態の追加
    const apiUrl = process.env.REACT_APP_API_URL;

    const fetchOtaFileName = useCallback(async () => {
        try {
            const response = await axios.get(`${apiUrl}/ota-file`);
            if (response.data === 'no file') {
                setOtaEnabledFile('')
            } else {
                setOtaEnabledFile(response.data.file);
            }
        } catch (error) {
            console.error('Error fetching OTA file name:', error);
            setAlert({ show: true, message: 'ファイル情報の取得に失敗しました', severity: 'error' });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // 初期化時にOTA可能なファイル名を取得
    useEffect(() => {
        fetchOtaFileName();
    }, [apiUrl, fetchOtaFileName]);

    // アップロードファイル設定
    const handleUploadFileChange = (event) => {
        const file = event.target.files[0];
        setUploadFile(file);
    };

    // ファイルアップロード処理
    const handleFileUpload = async () => {
        if (!uploadFile) {
            setAlert({ show: true, message: 'ファイルを選択してください', severity: 'error' });
            return;
        }
        
        setLoading(true); // ローディング開始
        
        const formData = new FormData();
        formData.append('uploadFile', uploadFile);

        try {
            await axios.post(`${apiUrl}/ota-file`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            setAlert({ show: true, message: 'ファイルをアップロードしました', severity: 'success' });
            fetchOtaFileName();
        } catch (error) {
            if (error.response.data === 'file name is invalid') {
                setAlert({ show: true, message: 'ファイル名が無効です', severity: 'error' });
            } else if (error.response.data === 'same version file already exists') {
                setAlert({ show: true, message: '同じバージョンのファイルが既に登録されています', severity: 'error' });
            } else {
                console.error('Error uploading file data:', error);
                setAlert({ show: true, message: 'ファイルのアップロードに失敗しました', severity: 'error' });
            }
        } finally {
            setLoading(false); // ローディング終了
        }
    };

    return (
        <Box>
            <Typography variant="body1" gutterBottom marginY={2}>
                OTA対象ファイル: {otaEnabledFile ? otaEnabledFile.file_name : "未登録"}
            </Typography>
            <Paper sx={
                { padding: 2, borderRadius: '8px', display: "flex", 
                textAlign: "center", backgroundColor: "#EEEEEE", marginBottom: 1 }
            }>
                <Typography variant="body1" marginRight={2} marginTop={0.8}>
                    OTAファイルアップロード:
                </Typography>
                <Button component="label" variant="outlined" size="small" startIcon={<CloudUploadIcon />}>
                    ファイルを選択
                    <input type="file" hidden onChange={handleUploadFileChange} />
                </Button>
                {uploadFile && (
                    <Typography variant="body2" color="textSecondary" marginTop={0.8} sx={{ marginLeft: 1}}>
                        選択されたファイル: {uploadFile.name}
                    </Typography>
                )}
                <Button variant="contained" color="primary" onClick={handleFileUpload} sx={{ marginX: 1}}>
                    アップロード
                </Button>
                {loading && <CircularProgress size={32}/>}
            </Paper>
        </Box>
    );

}

export default OtaFileSetting;