import React from 'react';
import { Grid, Button } from '@mui/material';
import { Link } from 'react-router-dom';

const ActionButtons = ({ buttons, userAuthority }) => {
    return (
        <Grid container spacing={2} justifyContent="center" sx={{ marginBottom: 4 }}>
            {buttons.filter(button => button.roles.includes(userAuthority))
                .map(button => (
                    <Grid item md={4} lg={2.4} key={button.to}>
                        <Button component={Link} to={button.to} variant="contained" color="primary" sx={{ width: '100%' }}>
                            {button.label}
                        </Button>
                    </Grid>
                ))
            }
        </Grid>
    );
};

export default ActionButtons;
