import React from 'react';
import { List, ListItem, ListItemText, Paper, CircularProgress, Box, Typography } from '@mui/material';

const ErrorLogs = ({ errorLogs, isLoading }) => {
    return (
        <Paper elevation={3} sx={{ padding: 2, backgroundColor: '#e8e5e5', borderRadius: '8px', marginY: 2 }}>
            <Typography variant="h6">エラーログ</Typography>
            {isLoading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
                    <CircularProgress />
                </Box>
            ) : (
                <List sx={{ maxHeight: '400px', overflowY: 'auto' }}>
                    {errorLogs.length !== 0 ? 
                        (errorLogs.map((log, index) => (
                            <ListItem key={index}>
                                <Paper elevation={1} sx={{ padding: 1, width: '100%', backgroundColor: '#f8f8f8'}}>
                                    <ListItemText 
                                        primary={`車台番号: ${log.frameNumber}`} 
                                        secondary={`エラー内容: ${log.errorMessages} (発生日時: ${log.timestamp})`} 
                                    />
                                </Paper>
                            </ListItem>
                        ))) : (
                            <ListItem>
                                <ListItemText primary="1週間以内のエラーログなし" />
                            </ListItem>
                        )
                    }
                </List>
            )}
        </Paper>
    );
};

export default ErrorLogs;
