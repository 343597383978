import React, { useState } from 'react';
import axios from 'axios';
import { Box, Dialog, DialogTitle, DialogContent, DialogActions, Button, Alert } from '@mui/material';
import { useForm, FormProvider } from 'react-hook-form';
import UserForm from '../../components/forms/user-form';
import transformUserData from '../../utils/forms/transform-user-data';

const UserModal = ({ item, onClose }) => {
    const user = item
    const [alert, setAlert] = useState({ show: false, message: '' });
    const apiUrl = process.env.REACT_APP_API_URL;

    const methods = useForm({
        defaultValues: {
            userName: user.user_name,
            userKana: user.user_kana,
            phoneNumber: user.phone_number,
            mailAddress: user.mail_address,
            zipCode: user.zip_code,
            address: user.address,
            address2: user ? user.address2 : '',
        }
    });
    const { handleSubmit } = methods;

    const onSubmit = async () => {
        const userData = transformUserData(methods.getValues());
        try {
            if (!user) userData.approvalFlg = '0';
            const response = await axios.put(`${apiUrl}/users/${user.user_code}`, userData) // 編集
            if (response.data.message === 'car not found') {
                setAlert({ show: true, message: '車両が見つかりませんでした' });
            } else {
                onClose(true);
            }
        } catch (error) {
            console.error('Error user modal:', error);
        }
    };

    return (
        <Dialog open onClose={() => onClose(false)} fullWidth>
            <DialogTitle sx={{ mx: 2 }}>賃借人{user ? '編集' : 'エントリー'}</DialogTitle>
            <DialogContent sx={{ mx: 2 }}>
                <Box component="form" onSubmit={handleSubmit(onSubmit)}>
                    <FormProvider {...methods}>
                        <UserForm/>
                        {alert.show && (
                            <Alert severity="error" onClose={() => setAlert({ show: false })}>
                                {alert.message}
                            </Alert>
                        )}
                    </FormProvider>
                </Box>
            </DialogContent>
            <DialogActions sx={{ m: 1 }}>        
                <Button onClick={handleSubmit(onSubmit)} variant="contained" color="primary">
                    {user ? '確定' : 'エントリー'}
                </Button>
                <Button onClick={() => onClose(false)}>キャンセル</Button>
            </DialogActions>
        </Dialog>
    );
}


export default UserModal;
