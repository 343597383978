import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { Container, Box, Alert, Paper, Typography } from '@mui/material';
import { AuthContext } from '../auth/AuthProvider';
import SearchComponent from './search-component';
import ConfirmModal from '../pages/modals/confirm-modal';
import ResultsTable from './result-tables';
import ManagementButtons from './buttons/buttons-management.jsx';
import SettingButtons from './buttons/buttons-setting.jsx';
import SimButtons from './buttons/buttons-sim.jsx';
import OtaFileSetting from './ota/ota-file-setting.jsx';
import OtaToggleComponent from './ota/ota-toggle-component.jsx';
import LoadingSpinner from './loading-spinner.jsx';
import ConnectCarWithUser from '../utils/connect-car-with-user.js';

function CommonManagement({ endpoint, dropdownOptions, tableHeaders, tableRowData, DetailModal, approvalFlg, buttonType, option=false }) {
    const { auth } = useContext(AuthContext);
    const [displayedList, setDisplayedList] = useState([]);
    const [selectedItem, setSelectedItem] = useState([]);
    const [showDetailModal, setShowDetailModal] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [action, setAction] = useState(null);
    const [alert, setAlert] = useState({ show: false, message: '', severity: 'error' });
    const [isLoading, setIsLoading] = useState(true);
    const apiUrl = process.env.REACT_APP_API_URL;
    const loginUser = auth.user

    // 識別子を取得する関数
    const getItemCode = (item, ep) => {
        if (ep.includes('car')) return item.car_code; // 車両
        if (ep.includes('user')) return item.user_code; // 賃借人
        if (ep.includes('admin')) return item.admin_code; // 管理者
        if (ep.includes('renter')) return item.renter_code; // 代理店
        if (ep.includes('tcu')) return item.tcu_code; // TCU
        return item.code; // デフォルト
    };

    // 対象のリストを取得
    const fetchItems = (async () => {
        try {
            setSelectedItem([]);
            setIsLoading(true);
            let params = { target: null, value: null, loginUser, approvalFlg };
            if (option === 'isEngCutOn') params.target = 'engCutOn';
            if (option === 'engCut') {
                if (displayedList.length > 0) {
                    params.target = 'code';
                    params.value = displayedList.map((item) => getItemCode(item, endpoint[0]));
                } else {
                    setIsLoading(false);
                    return;
                }
            }
            const responses = await Promise.all(
                endpoint.map((ep) => axios.get(`${apiUrl}${ep}`, { params }))
            );
            
            // エンドポイントがcarとuserを含む場合(未承認一覧の場合)、両者をつなげる
            let carList, userList;
            responses.forEach((response, index) => {
                if (endpoint[index].includes('car')) carList = response.data;
                if (endpoint[index].includes('user')) userList = response.data;
            });
            
            let data = responses[0].data;
            if (carList && userList) data = ConnectCarWithUser(carList, userList);
                
            setDisplayedList(data);
        } catch (error) {
            console.error(`Error fetching items:`, error);
        } finally {
            setIsLoading(false);
        }
    });

    // 検索結果をstateに設定
    const onDataFetched = (data) => {
        setDisplayedList(data);
    };

    // ボタンクリック時の処理
    const handleDetailClick = (action) => {
        // 登録
        if (action === 'register') {
            setAction(action);
            setShowDetailModal(true);
            return;
        }
        // 登録以外では選択された項目があるか確認
        if (selectedItem.length === 0) {
            setAlert({ show: true, message: '項目を選択してください', severity: 'error' });
            return;
        }
        // 削除、承認
        if (action === 'approve' || action === 'delete') {
            setAction(action);
            setShowConfirmModal(true);
            return;
        }
        // 編集、詳細の場合は選択された項目が1つか確認
        if (selectedItem.length > 1) {
            setAlert({ show: true, message: '1つの項目を選択してください', severity: 'error' });
            return;
        }
        // 編集、詳細
        setAction(action);
        setShowDetailModal(true);
    };

    // 削除、承認ボタン押下時の処理
    const handleConfirmAction = async () => {
        setShowConfirmModal(false);
        try {
            await Promise.all(endpoint.map(async (ep) => {
                const selectedItemCodeList = selectedItem.map((item) => getItemCode(item, ep));

                if (action === 'delete') {
                    await axios.delete(`${apiUrl}${ep}?codes=${selectedItemCodeList}`);
                    setAlert({ show: true, message: '削除しました', severity: 'success' });
                } else if (action === 'approve') {
                    await axios.put(`${apiUrl}/approve?codes=${selectedItemCodeList}`, { type: ep.slice(1, -1) });
                    setAlert({ show: true, message: '承認しました', severity: 'success' });
                }
            }));
            await fetchItems();
        } catch (error) {
            console.error(`Error ${action} ${endpoint.slice(1, -1)}:`, error);
        } finally {
            setSelectedItem([]);
        }
    };

    

    // 初回表示時にデータを取得
    useEffect(() => {
        if (loginUser) fetchItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loginUser]);

    if (auth.isLoading) {
        return (
            <LoadingSpinner />
        );
    }
    return (
        <Container maxWidth="xl">
            <Box mb={2}>
                <SearchComponent 
                    endpoint={endpoint}
                    dropdownOptions={dropdownOptions}
                    onDataFetched={onDataFetched} 
                    approvalFlg={approvalFlg}
                    setSelectedItem={setSelectedItem}
                    engCut={option === 'engCut'}
                    setIsLoading={setIsLoading}
                />
            </Box>
            {option === 'ota'
                && (
                <>
                    <OtaFileSetting setAlert={setAlert}/>
                    <Paper sx={
                        { padding: 2, borderRadius: '8px', display: "flex", 
                        textAlign: "center", backgroundColor: "#EEEEEE", marginBottom: 1 }
                    }>
                        <Typography variant="body1" marginRight={2} marginTop={0.8}>
                            OTA設定:
                        </Typography>
                        <OtaToggleComponent items={selectedItem} setAlert={setAlert} onComplete={fetchItems}/>
                    </Paper>
                    {alert.show && (
                    <Alert severity={alert.severity} onClose={() => setAlert({ show: false })}>
                        {alert.message}
                    </Alert>
                )}
                </>
                )
            }
            <Box>
                <ResultsTable
                    displayedList={displayedList}
                    selectedItem={selectedItem}
                    setSelectedItem={setSelectedItem}
                    tableHeaders={tableHeaders}
                    tableRowData={tableRowData}
                    option={option}
                    isLoading={isLoading}
                />
                {alert.show && option !== "ota" &&
                    <Alert severity={alert.severity} onClose={() => setAlert({ show: false })}>
                        {alert.message}
                    </Alert>
                }
                {buttonType === 'management' && (option !== 'tcu' || loginUser.authorityLevel === 4) &&
                    <ManagementButtons
                        approvalFlg={approvalFlg}
                        loginUser={loginUser}
                        handleDetailClick={handleDetailClick}
                    />
                }
                { buttonType === 'setting' && (option !== 'tcu-setting' || loginUser.authorityLevel === 4) &&
                    <SettingButtons
                        handleDetailClick={handleDetailClick}
                    />
                }
                { buttonType === 'sim' && loginUser.authorityLevel === 4 &&
                    <SimButtons
                        tcuCode={selectedItem[0] ? selectedItem[0].tcu_code : null}
                        fetchItems={fetchItems}
                    />
                }
            </Box>
            {showDetailModal && 
                <DetailModal 
                    item={(action === 'edit' || action === 'setting') ? selectedItem[0] : null} 
                    onClose={() => { setShowDetailModal(false); fetchItems(); }} 
                    setAlert={setAlert}
                />
            }
            {showConfirmModal && 
                <ConfirmModal
                    message={`本当に${action === 'delete' ? '削除' : '承認'}しますか？`}
                    onConfirm={handleConfirmAction}
                    onClose={() => setShowConfirmModal(false)}
                />
            }
        </Container>
    );
}

export default CommonManagement;
